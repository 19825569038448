<template src="./activityExams.html"></template>
<script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
<script>
import { subjectTypes } from "../../Constants/Utils/Statics";
import apiV3 from "../../NetworkManager/apiV3";
import ExamRepository from "../../Repository/Exam";
import InstituteRepository from "../../Repository/Institute";
import SemesterRepository from "../../Repository/Semester";
import NbaRepository from "../../Repository/NBA";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SubjectsRepository from "../../Repository/Subjects";
import ExamSettingsRepository from "../../Repository/ExamSettings";
import AssignmentRepository from "../../Repository/Assignment";
import AssignmentUserRepository from "../../Repository/AssignmentUser";
import html2pdf from "html2pdf.js";
import { parse, format } from "date-fns";
import { createExcelThroughJson } from "../../Utils/excel";
import numOps from "../../Services/Utils/numOps"
import arrOps from "../../Services/Utils/arrayOps";
import moment from "moment";
import { convertToISTTime } from "../../Services/Utils/DateTimeFormatter";

import {
  courseYearNumbersAbbr
} from "../../Constants/Utils/Statics";

import {
  timeStringConverter,
  convertDateTime,
} from "../../Services/Utils/DateTimeFormatter";
import { downloadFileViaUrl } from "../../Services/Utils/fileOperations";
import {
  addMinutes,
  addSeconds,
  compareAsc,
  isAfter,
  isFuture,
  isWithinInterval,
  parseISO,
} from "date-fns";
import auth from "../../Services/Firebase/auth";
export default {
  name: "activityExams",
  data() {
    return {
      isCorporate: false,
      currentBatch: {},
      allBatchSemIds: [],
      quickTestForEklvyaNew: [],
      fullNameOfUser: "",
      filteredExamsForHallTicket: [],
      instituteInfo: [],
      examSeatTable: [],
      hallTicketInfo: {},
      previewFormat: false,
      examDetailsForStudent: [],
      isEdcInstitute: false,
      selectedInstitute: "",
      options: {
        colors: ["#050992"],
        chart: {
          id: "exam-analysis",
          toolbar: { show: false },
        },
        xaxis: {
          categories: [],
        },
      },
      examOptions: {
        colors: ["#050992"],
        chart: {
          id: "exam-analysis",
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: (value) => {
              return `${value}%`;
            },
          },
          title: {
            text: "Percentage of marks",
            style: {
              cssClass: "apexCharts-yAxis-title",
            },
          },
        },
        markers: {
          size: 6,
          strokeWidth: 1,
          strokeColor: "#050992",
          colors: ["#fff"],
          fillOpacity: 0,
          hover: {
            size: 8,
          },
        },
        stroke: {
          width: 1,
        },
      },
      examSeries: [],
      series: [],
      selectedTabOne: true,
      isMobileView: false,
      subjectsMapping: {},
      subjectTypes: subjectTypes,
      userData: {},
      items: [],
      examTest: [],
      manageExams: [],
      showingAnalysis: false,
      examAnalysis: {},
      examName: "",
      subjectName: "",
      examType: "",
      quickTestHeaders: [
        { text: "Name", value: "name" },
        { text: "Subject", value: "subjectName" },
        { text: "Date", value: "dateTimeUTC" },
        { text: "Duration", value: "durationInMins" },
        { text: "Passing Percent", value: "passingPercent" },
        { text: "Marks", value: "totalMarks" },
        { text: "Obtained Marks", value: "totalMarksObtained" },
        { text: "Action", value: "actions" },
      ],
      eklvyaHeaders: [
        {
          text: "Test Name",
          value: "ExamName",
        },
        {
          text: "StartDate (dd/mm/yyyy)",
          value: "ScheduleStartDate",
        },
        {
          text: "EndDate (dd/mm/yyyy)",
          value: "ScheduleEndDate",
        },
        // {
        //   text: 'Duration (minutes)',
        //   value: 'ExamDuration'
        // },
        // {
        //   text: 'ScheduleDate',
        //   value: 'ScheduleStartDate'
        // },
        {
          text: "Status",
          value: "IsExamComplete",
        },
        {
          text: "AttendExam",
          value: "url",
        },
        {
          text: "Result",
          value: "ResultURL",
        },
        {
          text: "Analysis",
          value: "AnalysisURL",
        },
      ],
      examHeaders: [
        { text: "Name", value: "examName" },
        { text: "Subject", value: "subjectName" },
        { text: "Type", value: "examType" },
        { text: "Date", value: "examDate" },
        { text: "Duration", value: "durationInMins" },
        { text: "Passing Marks", value: "passingMarks" },
        { text: "Obtained Marks", value: "obtainedMarks" },
        { text: "Status", value: "status", align: "center" },
      ],
      headers: [
        {
          text: "Name",
          value: "examName",
        },
        {
          text: "Subject",
          value: "subjectName",
        },
        {
          text: "Subject Type",
          value: "subjectType",
        },
        {
          text: "Exam Type",
          value: "examType",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Duration (in Mins.)",
          value: "duration",
        },
        {
          text: "Marks Obtained",
          value: "marksObtained",
        },
        {
          text: "",
          value: "appear",
        },
      ],
      allSubjectHeader: [
        {
          text: "Subject Name",
          value: "subjectName",
        },
        {
          text: "Subject Code",
          value: "subjectCode",
        },
        {
          text: "Marks",
          value: "totalMarks",
        },
        {
          text: "Internal",
          value: "totalCieObtainedMarks",
        },
        {
          text: "External",
          value: "totalSeeObtainedMarks",
        },
        {
          text: "Total Obtained",
          value: "obtainedMarks",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
      ],
      allSubjectData: [],
      maximumCredits: 0,
      quickTest: [],
      exams: [],
      studentExam: [],
      instituteData: null,
      isLoadingMagaeExams: false,
      isLoadingExams: false,
      isLoadingExamsInfo: false,
      subjectsMappingArray: [],
      selectedSubjectId: "",
      subjectAverage: 0,
      hasNoData: false,
      allCourseYears: [],
      selectedCourseYear: "",
      allAssignments: [],
      totalMarksOfAllExams: 0,
      sumOfObtainedMarksInAllExams: 0,
      overallPerformance: 0,
      examSettings: {},
      performanceStatus: "-",
      passingMarks: 0,
      allExams: [],
      allQuickTestIds: [],
      allQuickTestAnswers: [],
      selectedExamsType: "primary",
      filteredExams: [],
      filteredAssignments: [],
      allSubmittedAssignments: [],
      filteredSeeExams: [],
      filteredCieExams: [],
      examFormDetails: false,
      selectedSectionType: "",
      tabs: 0,
      feeStatus: true,
      approvalStatus: false,
      studentEmail: "",
      studentGender: "",
      studentCategory: "",
      examFormInstructionsForStudent: "",
      examFormLoader: false,
      examPath: [
        {
          text: "Exam Form",
          value: 0,
        },
        {
          text: "Quick Test and Exam Performance",
          value: 1,
        },
        // {
        //   text: "Hall Ticket",
        //   value: 2
        // },
        // {
        //   text: "Mark Sheet",
        //   value: 3
        // },
      ],
      currentExams: [],
      dataLoader: false,
      allAttemptAnswers: [],
      resultDownload: false,
      studentSemesterDetails: {},
      downloadResultLoader: false,
      batchData: [],
      batchSemesters: [],
      disableDownloadResult: false,
      yAxisData: [],
      xAxisData: [],
      isResultPublishedToStudent: false,
      customMarksheetTemplate: 0,
      semName: "",
      isStmiras: false,
      modifiedSemesterName: "",
      SubjectDetailsOfACourseYear: [],
      resultExcelLoader: false,
      resultReportData: [],
      splitSem: [],
      splitSemNumber: [],
      splitCourseYear: [],
      semNumberAfterSplit: '',
      studentExamsData: {},
      allSubjectDropdown: [],
      studentAllSemesterDetails: [],
      currentInstitute: ""
    };
  },
  async created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.examRepositoryInstance = new ExamRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.InstituteRepositoryInstance = new InstituteRepository(this);
    this.SemesterRepositoryInstance = new SemesterRepository(this);
    this.SemesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.assignmentRepositoryInstance = new AssignmentRepository(this);
    this.assignmentUserRepositoryInstance = new AssignmentUserRepository(this);

    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    // this.subjectsMappingArray = Object.keys(this.subjectsMapping).map((key) => this.subjectsMapping[key]);
    const data = this.$store.getters["instituteData/get_applicableInstitutes"];
    this.instituteData = data[0];
    if (
      this.instituteData.instituteId === "2939ed29-4649-4043-8498-f4eb3b0eac9e"
    ) {
      //Disable for RIIMS
      this.currentInstitute = "RIIMS"
    }
    this.$store.commit("liveData/set_selectedActivityName", "Exams");
    this.userData = this.$store.getters["user/get_userData"];
    try {
        this.studentAllSemesterDetails =
              await this.SemesterUserRepositoryInstance.getStudentsByInstituteAndDepartmentAndUid({
                department: this.userData.department,
                uId: this.userData.uId,
                instituteId: this.userData.instituteId,
              });
        } catch (error) {
        console.error("error", error);
      }
    this.splitSem = this.userData.semName.split(" ")
    this.splitCourseYear = this.userData.courseYear.split(" ")
    if(this.splitSem.length > 2) {
      this.splitSemNumber = this.splitSem[1].split('/')
    } else if(this.splitSem.length === 2) {
      this.splitSemNumber = this.splitSem[0].split('/')
    }
    this.semNumberAfterSplit = this.splitSemNumber[courseYearNumbersAbbr[this.splitCourseYear[0]]-1]
    this.studentEmail = this.userData.email ? this.userData.email : "-";
    this.contactNumber = this.userData.mobile ? this.userData.mobile : "-";
    this.studentGender = this.userData.gender ? this.userData.gender : "-";
    this.studentCategory = this.userData.category
      ? this.userData.category
      : "-";
    this.isCorporate = this.$store.getters["liveData/get_isCorporateInstitute"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    if (this.selectedInstitute === "efe22e7d-5c51-4163-bb86-94119768ac79") {
      this.isEdcInstitute = true;
    }
    if (
      this.selectedInstitute === "6509f460-cdc3-494d-bae3-23029b3d7f04" ||
      this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4"
    ) {
      this.isStmiras = true;
    }
    if (this.userData.middleName === "") {
      this.fullNameOfUser =
        this.userData.firstName + " " + this.userData.lastName;
    } else {
      this.fullNameOfUser =
        this.userData.firstName +
        " " +
        this.userData.middleName +
        " " +
        this.userData.lastName;
    }
    this.dataLoader = true;

    await this.getSemesterUserDetails();
    await this.getExamSettings();
    await this.getSemesterDetails();
    await this.getAllExamDetails();
    if (this.isEdcInstitute === false) {
      this.getAllDataForHallTicket();
    }
    await this.getBatches();
    this.getInstituteCustomizationFromFirebase();
    this.handleTypeOfExams('primary')
    this.dataLoader = false;

  },
  watch: {
    kjbf() {
      if (this.xAxisData.length > 0) {
      }
    },
  },
  methods: {
    gotoExamForm(exam) {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityExamForm2",
          params: {
            prop_exam: exam,
            prop_institute: this.instituteInfo,
          },
        });
      } else {
        this.$router.push({
          name: "activityExamForm",
          params: {
            prop_exam: exam,
            prop_institute: this.instituteInfo,
          },
        });
      }
    },
    handleSection(type) {
      this.selectedSectionType = type;
    },
    redirectToExam(examURL) {
      window.open(examURL, "_blank");
    },
    async getSemesterUserDetails() {
      try {
        const response =
          await this.SemesterUserRepositoryInstance.getSemesterUserForACourseYear({
            semId: this.userData.semId,
            uId: this.userData.uId,
            instituteId: this.userData.instituteId,
            courseYear: this.userData.courseYear,
            department: this.userData.department
          });
          this.studentSemesterDetails = response[0]
          if(this.studentSemesterDetails.publishResult){
            this.isResultPublishedToStudent =
              this.studentSemesterDetails.publishResult;
          }
      } catch (error) {
        console.error("error", error);
      }
    },
    async getInstituteCustomizationFromFirebase() {
      const result = await auth.getInstituteCustomizationFromFirebase(
        this.selectedInstitute
      );
      if (result.marksheetTemplate) {
        this.customMarksheetTemplate = result.marksheetTemplate;
      }
      // if(result.consolidatedMarksheetExcel) {
      //   this.consolidatedMarksheetExcel = result.consolidatedMarksheetExcel
      // }
    },
    roundOffUptoTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    downloadExcelReport() {
      this.resultExcelLoader = true;
      createExcelThroughJson(this.resultReportData, "Temp excel");
      this.resultExcelLoader = false;
    },
    async downloadResult() {
      this.downloadResultLoader = true;
      let totalCreditPoints = 0;
      this.maximumCredits = 0
      let totalSubjectGradePoints = 0;
      let subjectCreditsSum = 0;
      let totalSubjectMarks = 0;
      let totalSubjectObtainedMarks = 0;
      let totalGradePointsSum = 0;
      this.studentSemesterDetails.semesterSubjectMarks.map((item) => {
        let subjectDetails = this.subjectsMappingArray.filter(
          (sub) => sub.subjectId === item.subjectId
        )[0];
        this.maximumCredits += subjectDetails.credits
        subjectCreditsSum += isNaN(item.obtainedCredits)
              ? 0
              : item.obtainedCredits;
        totalCreditPoints += item.gradePoints;
        // totalSubjectGradePoints += item.subjectGradePoints ? item.subjectGradePoints : item.gradePoints;
        // subjectCreditsSum += item.obtainedCredits;
        totalSubjectMarks += item.totalMarks;
        totalSubjectObtainedMarks += item.obtainedMarks;
        // totalGradePointsSum += item.gradePoints;
        item.subjectName = subjectDetails.subjectName;
        item.subjectCode = subjectDetails.subjectCode;
        item.semNumber = Number(this.semNumberAfterSplit);

        if(item.totalExternalMarks === 0 && this.selectedInstitute === "2939ed29-4649-4043-8498-f4eb3b0eac9e") {
            item.totalObtainedExternalMarks = "NA"
          }
          if(item.totalInternalMarks === 0 && this.selectedInstitute === "2939ed29-4649-4043-8498-f4eb3b0eac9e") {
            item.totalObtainedInternalMarks = "NA"
          }
      });
      // this.studentSemesterDetails["totalCreditPoints"] = totalCreditPoints;
      // this.studentSemesterDetails["totalSubjectGradePoints"] =
      //   totalSubjectGradePoints;
      this.studentSemesterDetails["totalCredits"] = subjectCreditsSum;
      this.studentSemesterDetails["totalSubjectMarks"] = totalSubjectMarks;
      this.studentSemesterDetails["totalSubjectObtainedMarks"] =
        totalSubjectObtainedMarks;
      // this.studentSemesterDetails["totalGradePointsSum"] = totalGradePointsSum;
      let fullName =
        this.userData.firstName +
        " " +
        this.userData.middleName +
        " " +
        this.userData.lastName;
      this.studentSemesterDetails["name"] = fullName;
      this.studentSemesterDetails["studentPrn"] = this.userData.collegePRNNo;
      this.currentBatch = this.batchData.filter((item) =>
        item.batch.some(
          (batchItem) =>
            batchItem.courseYear === this.studentSemesterDetails.courseYear
        ) &&
        item.department === this.studentSemesterDetails.department
      )[0];
      //all sgpas
      let semNo;
      this.batchData.map((item) => {
        item.batch.map((batchItem) => {
          if (batchItem.courseYear === this.studentSemesterDetails.courseYear) {
            batchItem.semesters.map((sem, index) => {
              if (sem.semId === this.studentSemesterDetails.semId) {
                semNo = index + 1;
              }
            });
          }
        });
      });
      let objToPushSpgas = {};
      objToPushSpgas["semId"] = this.studentSemesterDetails.semId;
      objToPushSpgas["sgpa"] = this.studentSemesterDetails.finalSgpa;
      objToPushSpgas["semNumber"] = Number(this.semNumberAfterSplit);
      this.studentSemesterDetails["allSgpas"] = [objToPushSpgas];
      let objToPushGroupedSubj = {};
      objToPushGroupedSubj["semId"] = this.studentSemesterDetails.semId;
      objToPushGroupedSubj["semName"] = this.semName;
      const studentSubjects = arrOps.sortByProperty(this.studentSemesterDetails.semesterSubjectMarks, "subjectCode", 'asc')
      objToPushGroupedSubj["subjects"] = [
        ...studentSubjects,
      ];
      this.studentSemesterDetails["groupedSubjects"] = [objToPushGroupedSubj];
      if (this.isStmiras) {
        this.studentSemesterDetails["abcIdNumber"] = [
          this.userData.abcIdNumber,
        ];
        this.studentSemesterDetails["grNumber"] = [this.userData.grNumber];
        this.studentSemesterDetails["motherName"] = [this.userData.motherName];
      }
      this.instituteInfo.marksheetTemplate.tableData = [];
      this.instituteInfo.marksheetTemplate.studentPersonalDetails = [];
      this.instituteInfo.marksheetTemplate.personalDetails.map((item) => {
        let objToPush = {};
        if (item === "Student Name") {
          objToPush = {
            text: "Student Name",
            value: "name",
          };
        }
        if (item === "Roll No") {
          objToPush = {
            text: "Roll No",
            value: "rollNumber",
          };
        }
        if (item === "PRN") {
          objToPush = {
            text: "PRN",
            value: "studentPrn",
          };
        }
        if (item === "Mother's Name") {
          objToPush = {
            text: "Mother's Name",
            value: "motherName",
          };
        }
        this.instituteInfo.marksheetTemplate.studentPersonalDetails.push(
          objToPush
        );
      });
      this.instituteInfo.marksheetTemplate.tableFields.map((item) => {
        let objToPush = {};
        if (item === "Course Code") {
          objToPush = {
            text: "Course Code",
            value: "subjectCode",
          };
        }
        if (item === "Course Name") {
          objToPush = {
            text: "Course Name",
            value: "subjectName",
          };
        }
        if (item === "Course Type") {
          objToPush = {
            text: "Course Type",
            value: "subjectType",
          };
        }
        if (item === "Credits") {
          objToPush = {
            text: "Credits",
            value: "obtainedCredits",
          };
        }
        if (item === "Grade Point") {
          objToPush = {
            text: "Grade Point",
            value: "gradePoints",
          };
        }
        if (item === "Grade") {
          objToPush = {
            text: "Grade",
            value: "obtainedGrade",
          };
        }
        // if (item === "Internal") {
        //   objToPush = {
        //     text: "Internal",
        //     value: "totalObtainedInternalMarks",
        //   };
        // }
        // if (item === "External") {
        //   objToPush = {
        //     text: "External",
        //     value: "totalObtainedExternalMarks",
        //   };
        // }
        if (item === "Internal") {
            objToPush = {
              text: this.currentInstitute === "RIIMS" ? "CCE" : "Internal",
              value: "totalObtainedInternalMarks",
            };
          }
          if (item === "External") {
            objToPush = {
              text: this.currentInstitute === "RIIMS" ? "ESE" : "External",
              value: "totalObtainedExternalMarks",
            };
          }
        if (item === "Total") {
          objToPush = {
            text: "Total",
            value: "totalObtainedMarks",
          };
        }
        if (item === "Grade Point Average") {
          objToPush = {
            text: "Grade Point Average",
            value: "creditPoints",
          };
        }
        this.instituteInfo.marksheetTemplate.tableData.push(objToPush);
      });
      if (this.currentInstitute === "RIIMS") {
        this.instituteInfo.marksheetTemplate.tableData.unshift({
          text: "Sem No",
          value: "semNumber",
        })
      }
      this.studentSemesterDetails["overallTotalCredits"] = 0;
      this.studentSemesterDetails["overallObtainedCredits"] = 0;
      this.studentSemesterDetails["overallTotalMarks"] = 0;
      this.studentSemesterDetails["overallObtainedMarks"] = 0;
      let temp = this.SubjectDetailsOfACourseYear.filter(item => (item?.totalCieMarks > 0 || item?.totalInternalMarks > 0) && (item.totalSeeMarks > 0 || item.totalExternalMarks > 0 ))[0]
      this.studentSemesterDetails['totalInternalMarks'] = temp?.totalCieMarks || item?.totalInternalMarks || 0
      this.studentSemesterDetails['totalExternalMarks'] = temp?.totalSeeMarks || item.totalExternalMarks || 0
      this.studentSemesterDetails['maximumCredits'] = this.maximumCredits
      // this.studentSemesterDetails.groupedSubjects
      this.studentSemesterDetails.groupedSubjects.map((sem) => {
        sem.obtainedCredits = 0;
        sem.totalCredits = 0;
        sem.obtainedGradePointsSum = 0;
        sem.totalMarks = 0;
        sem.obtainedMarks = 0;
        sem.sgpa = this.studentSemesterDetails.finalSgpa;
        sem.subjects.map((subject) => {
          sem.obtainedMarks += subject.obtainedMarks ? subject.obtainedMarks : subject.totalObtainedMarks;
          sem.totalMarks += subject.totalMarks;
          sem.obtainedGradePointsSum += subject.gradePoints;
          if (
            subject.graceMarksSymbol === "undefined" ||
            !subject.graceMarksSymbol
          ) {
            subject.graceMarksSymbol = "";
          }
          sem.totalCredits += this.SubjectDetailsOfACourseYear?.filter(
            (sub) => sub.subjectId === subject.subjectId
          )[0]?.credits;
          sem.obtainedCredits += isNaN(subject.obtainedCredits)
            ? 0
            : subject.obtainedCredits;

          this.studentSemesterDetails.overallTotalCredits +=
            this.SubjectDetailsOfACourseYear?.filter(
              (sub) => sub.subjectId === subject.subjectId
            )[0]?.credits;
          this.studentSemesterDetails.overallObtainedCredits += isNaN(
            subject.obtainedCredits
          )
            ? 0
            : subject.obtainedCredits;
          this.studentSemesterDetails.overallTotalMarks += subject.totalMarks;
          this.studentSemesterDetails.overallObtainedMarks += isNaN(
            subject.obtainedCredits
          )
            ? 0
            : subject.totalObtainedMarks;
          subject["totalObtainedMarksWithGraceMarks"] =
            subject.obtainedMarks + subject.totalObtainedGraceMarks;
        });
      });
      let tempPercentage =
        (this.studentSemesterDetails.overallObtainedMarks /
          this.studentSemesterDetails.overallTotalMarks) *
        100;
      let percentage = isNaN(tempPercentage) ? 0 : tempPercentage;
      this.studentSemesterDetails["percentage"] =
        this.roundOffUptoTwoDecimals(percentage);
        this.allBatchSemIds = []
        for(let i = 0 ; i < this.currentBatch?.batch.length; i++) {
          for(let j = 0 ; j < this.currentBatch?.batch[i]?.semesters.length; j++) {
            this.allBatchSemIds.push(this.currentBatch?.batch[i]?.semesters[j].semId)
          }
        }
      let semIdIndex = this.allBatchSemIds.indexOf(this.userData.semId)+1
      let semIdsBeforeCurrentSem = this.allBatchSemIds.slice(0, semIdIndex)
      let semSubMarksOfAllSems = []
      for(let i = 0 ; i < this.studentAllSemesterDetails.length; i++) {
        for(let j = 0 ; j < semIdsBeforeCurrentSem.length; j++) {
          if(this.studentAllSemesterDetails[i].semId === semIdsBeforeCurrentSem[j]) {
            semSubMarksOfAllSems.push(...this.studentAllSemesterDetails[i].semesterSubjectMarks)
          }
        }
      }
      let totalObtainedGradePoints = 0;
      let totalObtainedCreditsOfSubjects = 0;
      let cgpa = 0;
      for (
        let j = 0;
        j < semSubMarksOfAllSems.length;
        j++
      ) {
        const subject =
        semSubMarksOfAllSems[j];
        totalObtainedCreditsOfSubjects += isNaN(subject.obtainedCredits)
        ? 0
        : subject.obtainedCredits;
        let temp = 0
        if(subject.creditPoints !== undefined) {
          temp = subject.creditPoints
        } else {
          temp = subject.obtainedGradePoints
        }
        totalObtainedGradePoints += temp;
      }
      this.studentSemesterDetails["totalObtainedCredits"] =
      totalObtainedCreditsOfSubjects;
      this.studentSemesterDetails["totalObtainedGradePoints"] =
      totalObtainedGradePoints
      let tempcgpa =
      totalObtainedGradePoints / totalObtainedCreditsOfSubjects;
      if(this.studentSemesterDetails.finalSgpa === 0) tempcgpa = 0

      isNaN(tempcgpa) ? (cgpa = 0) : (cgpa = tempcgpa);
      this.studentSemesterDetails["finalCgpa"] =
        this.roundOffUptoTwoDecimals(cgpa);
      try {
        // console.log('semIdIndex', semIdIndex);
        const objToPush = {
          studentData: [this.studentSemesterDetails],
          instituteData: {
            instituteName: this.instituteData.instituteName,
            courseYear: this.studentSemesterDetails.courseYear,
            instituteAddress: this.instituteInfo.instituteAddress,
            instituteLogoLink: this.instituteData.instituteLogoLink,
            department: this.studentSemesterDetails.department,
            semesterName: this.semName,
            marksheetTemplate: this.instituteInfo.marksheetTemplate,
            customMarksheetTemplate: this.customMarksheetTemplate,
            batchName: this.currentBatch.batchName,
            currentSemesterNumber: semIdIndex
          },
        };
        const response = await apiV3.postRequest(
          "/manageExam/generateStudentMarksheet",
          objToPush
        );
        let errorMessage =
          "Error downloading marksheet. Please try after some time.";
        let successMessage = "Marksheet downloaded successfully.";
        let thisObject = this;
        if (response.data.pdfUrl) {
          downloadFileViaUrl(
            response.data.pdfUrl,
            `${this.fullNameOfUser}_${this.semName}_Marksheet`,
            successMessage,
            errorMessage,
            thisObject
          );
        }
      } catch (error) {
        console.error("error", error);
      }
      this.downloadResultLoader = false;
    },
    async getBatches() {
      try {
        this.batchSemesters = [];
        this.batchData = await this.nbaRepositoryInstance.getBatchesOfInstitute(
          {
            lmsInstituteId: this.selectedInstitute,
          }
        );
        this.batchData.map((item) => {
          item.batch.map((bat) => {
            this.batchSemesters.push(...bat.semesters);
          });
        });
      } catch (error) {
        console.error("error", error);
      }
    },
    downloadPDFHallTicket() {
      const watermarkElement = document.createElement("div");
      watermarkElement.classList.add("waterMark");
      watermarkElement.innerText = this.instituteInfo.instituteName;

      const element = this.$refs.hallTicket; // Assuming this is the target HTML element to convert to PDF

      element.appendChild(watermarkElement); // Append the watermark to the target element

      html2pdf()
        .from(element)
        .set({
          margin: [0, 0],
          filename: "hallTicket.pdf",
          enableLinks: true,
          image: { type: "jpg", quality: 0.1, maxHeight: 600, maxWidth: 800 },
          html2canvas: { useCORS: true, windowWidth: 1300, windowHeight: 700 },
          jsPDF: {
            unit: "in",
            orientation: "portrait",
            format: "a4",
            compress: true,
            minify: { forcePDFJS: true },
          },
        })
        .save();
      this.previewFormat = false;
    },

    convertToAMPM(time) {
      let hours = parseInt(time.substr(0, 2));
      let minutes = time.substr(3, 2);
      let ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12; // Convert midnight (0) to 12

      let formattedTime = hours + ":" + minutes + " " + ampm;
      return formattedTime;
    },
    examSeatValue(data) {
      if (data === "PRN number") {
        return this.userData.collegePRNNo;
      }
      if (data === "Exam seat number") {
        return this.userData.examSeatNumber;
      }
      if (data === "PUN code") {
        return "-";
      }
      if (data === "College code") {
        return "-";
      }
      if (data === "Center code") {
        return "-";
      }
      // stu.rollNo = semData.rollNumber
      //         stu.examNo = semData.examSeatNumber
    },
    tableValueSetUp(data, index, arr) {
      let courseYear = "";
      let department = "";
      this.filteredExamsForHallTicket.map((data) => {
        if (data.subjectDetails.includes(arr._id)) {
          courseYear = data.criteria.courseYear;
          department = data.criteria.department;
        }
      });

      if (data === "Department") {
        return department === "" ? "-" : department;
      }
      if (data === "Course Year") {
        return courseYear === "" ? "-" : courseYear;
      }
      if (data === "Subject Code") {
        return arr.subjectCode !== undefined ? arr.subjectCode : "-";
      }
      if (data === "Subject Name") {
        return arr.subjectName;
      }
      if (data === "Exam Day") {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];

        let date1 = new Date(arr.selectedDate);
        let dayName = daysOfWeek[date1.getDay()];
        return dayName;
      }
      if (data === "Exam Date") {
        return arr.selectedDate;
      }
      if (data === "Exam Time") {
        // Start time in string format
        const startTimeString = arr.selectedTime;

        // Convert start time to Date object
        const startTime = new Date();
        const [startHours, startMinutes] = startTimeString.split(":");
        startTime.setHours(Number(startHours));
        startTime.setMinutes(Number(startMinutes));

        const minutesToAdd = Number(arr.duration);

        startTime.setMinutes(startTime.getMinutes() + minutesToAdd);

        const updatedTimeString = startTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        return (
          this.convertToAMPM(arr.selectedTime) +
          " - " +
          this.convertToAMPM(updatedTimeString)
        );
      }
    },
    async getAllDataForHallTicket() {
      try {
        this.instituteInfo =
          await this.InstituteRepositoryInstance.getInstituteInformation({
            instituteId: this.userData.instituteId,
          });
        this.examFormInstructionsForStudent =
          this.instituteInfo?.examFormFormat?.formInstructions;
        this.hallTicketInfo = this.instituteInfo.hallTicketFormat;
        this.examSeatTable = [];
        if (this.hallTicketInfo) {
          this.hallTicketInfo.studentDetails.filter((data) => {
            if (
              data !== "Student photo" &&
              data !== "Full Name" &&
              data !== "Mother’s Name"
            ) {
              this.examSeatTable.push(data);
            }
          });
        }
        let data = await this.examRepositoryInstance.allExamForHallTicket({
          instituteId: this.userData.instituteId,
          uId: this.userData.uId,
          subjects: this.userData.subjects,
        });
        this.filteredExamsForHallTicket = [];
        let subjectDetails = [];
        if (data.exams.length > 0) {
          this.filteredExamsForHallTicket = data.exams.filter((info) => {
            let today = new Date();
            let date2 = new Date(today);
            const formattedDate = date2.toISOString();

            if (info.startDate && info.startDate > formattedDate) {
              return info;
            }
          });
        }
        if (data.subjectDetails.length > 0) {
          subjectDetails = data.subjectDetails.filter((info) => {
            if (info.selectedDate !== "") {
              let str = String(info.selectedDate);
              let [a, b, c] = str.split("-");
              if (new Date(a, b - 1, c) > new Date()) {
                return info;
              }
            }
          });
        }
        subjectDetails.sort((a, b) => {
          const dateA = new Date(a.selectedDate);
          const dateB = new Date(b.selectedDate);
          return dateA - dateB;
        });
        this.examDetailsForStudent = subjectDetails;
      } catch (error) {
        console.error(error);
        this.hallTicketInfo.examDetails = [];
      }
    },
    timeStringConverter(time) {
      return timeStringConverter(time);
    },
    async handleSubjectChange(subjectId) {
      await this.fetchExams();
      // await this.getAllExamDetails();
      await this.getAllAssignments();
      this.handleExamType(subjectId);
    },
    highlightBacklogExam(item) {
      // if (item.status === "Fail") {
      //   return "high";
      // } else {
      //   return "";
      // }
    },
    checkForPromotion(sub) {
      if (sub.considerForPromotion) {
        return true;
      } else {
        return false;
      }
    },
    roundOffUpto2Decimals(value) {
      return Math.round(value * 100) / 100;
    },
    filterExamsOfSelectedSubject(paramSubjectId) {
      let subjectExams = [];
      this.studentExam = [];

      this.allExams.forEach(exam => {
        if (exam.subjectIds.includes(paramSubjectId)) {
          subjectExams.push(exam);
        }
      });

      subjectExams.forEach(exam => {
        exam.examSubjectsAndQuestions.forEach(subject => {
          if (subject.subjectId === paramSubjectId) {
            let obj = {
              examName: exam.title,
              subjectName: '',
              examType: exam.examType,
              examDate: subject.examDate,
              durationInMins: subject.examDuration || 0,
              passingMarks: subject.passingMarks,
              subjectId: subject.subjectId,
              obtainedMarks: "",
              status: '-',
              examMarks: subject.examMarks
            };
            this.studentExam.push(obj);
          }
        });
      });
      this.subjectsMappingArray.forEach(subject => {
        this.studentExam.forEach(exams => {
          if(exams.subjectId === subject.subjectId) {
            exams.subjectName = subject.subjectName
          }
        })
      })
      this.studentExam.forEach(exams => {
        exams?.examMarks?.forEach(marks => {
          if(marks.studentId === this.userData.uId) {
            exams.obtainedMarks = marks.obtainedMarks
            exams.total = marks.total
            if(exams.passingMarks > exams.obtainedMarks) exams.status = 'Fail'
            else exams.status = 'Pass'
          }
        })
        })
    },
    handleExamType(paramSubjectId) {
      if(paramSubjectId !== 'all') {
        this.filterExamsOfSelectedSubject(paramSubjectId)
      }
      // this.filterExams(paramSubjectId)
      const totalExams = [...this.allAssignments, ...this.allExams];
      this.filteredExams = [];
      this.filteredSeeExams = [];
      this.filteredCieExams = [];
      // if (this.examSettings.systemPreference === "percentage") {
      if (this.examSettings.selectedCieSeePreference === "no") {
        if (this.selectedExamsType === "primary") {
          for (let index = 0; index < totalExams.length; index++) {
            const element = totalExams[index];
            if (element.examId) {
              if (paramSubjectId === "all") {
                let result;
                if (element.subjectDetails.length > 0) {
                  result = element.subjectDetails.every(this.checkForPromotion);
                  if (result) {
                    this.filteredExams.push(element);
                  }
                }
              } else {
                let result;
                if (element.subjectDetails.length > 0) {
                  result = element.subjectDetails.filter(
                    (item) => item.subjectId === paramSubjectId
                  )[0];
                  if (result) {
                    if (result.considerForPromotion) {
                      this.filteredExams.push(element);
                    }
                  }
                }
              }
            } else if (element.assignmentId) {
              if (paramSubjectId === "all") {
                if (element.considerForPromotion) {
                  this.filteredExams.push(element);
                }
              } else {
                if (element.subjectId === paramSubjectId) {
                  if (element.considerForPromotion) {
                    this.filteredExams.push(element);
                  }
                }
              }
            }
          }
        } else {
          this.filteredExams = [...totalExams];
        }
      } else {
        if (this.selectedExamsType === "primary") {
          for (let index = 0; index < totalExams?.length; index++) {
            const element = totalExams[index];
            if (element.examId) {
              if (paramSubjectId === "all") {
                let result;
                if (element?.subjectDetails?.length > 0) {
                  result = element.subjectDetails.every(this.checkForPromotion);
                  if (result) {
                    if (element.isForNBA) {
                      if (element.nbaAttainment.typeOfExam === "cie") {
                        this.filteredCieExams.push(element);
                      } else if (element.nbaAttainment.typeOfExam === "see") {
                        this.filteredSeeExams.push(element);
                      }
                    } else {
                      if (element.type === "CIE(Internal)") {
                        this.filteredCieExams.push(element);
                      } else if (element.type === "SEE(External)") {
                        this.filteredSeeExams.push(element);
                      }
                    }
                  }
                }
              } else {
                let result;
                if (element?.subjectDetails?.length > 0) {
                  result = element.subjectDetails.filter(
                    (item) => item.subjectId === paramSubjectId
                  )[0];
                  if (result) {
                    if (element.isForNBA) {
                      if (element.nbaAttainment.typeOfExam === "cie") {
                        if (result.considerForPromotion) {
                          this.filteredCieExams.push(element);
                        }
                      } else if (element.nbaAttainment.typeOfExam === "see") {
                        if (result.considerForPromotion) {
                          this.filteredSeeExams.push(element);
                        }
                      }
                    } else {
                      if (element.type === "CIE(Internal)") {
                        if (result.considerForPromotion) {
                          this.filteredCieExams.push(element);
                        }
                      } else if (element.type === "SEE(External)") {
                        if (result.considerForPromotion) {
                          this.filteredSeeExams.push(element);
                        }
                      }
                    }
                  }
                }
              }
            } else if (element.assignmentId) {
              if (paramSubjectId === "all") {
                if (element.considerForPromotion) {
                  this.filteredCieExams.push(element);
                }
              } else {
                if (element.subjectId === paramSubjectId) {
                  if (element.considerForPromotion) {
                    this.filteredCieExams.push(element);
                  }
                }
              }
            }
          }
        } else {
          for (let index = 0; index < totalExams?.length; index++) {
            const element = totalExams[index];

            if (element.examId) {
              if (element.isForNBA) {
                if (element.nbaAttainment.typeOfExam === "cie") {
                  this.filteredCieExams.push(element);
                } else if (element.nbaAttainment.typeOfExam === "see") {
                  this.filteredSeeExams.push(element);
                }
              } else {
                if (element.type === "CIE(Internal)") {
                  this.filteredCieExams.push(element);
                } else if (element.type === "SEE(External)") {
                  this.filteredSeeExams.push(element);
                }
              }
            } else {
              this.filteredCieExams.push(element);
            }
          }
        }
      }
      // }
      this.calculateResults();
    },
    async getExamSettings() {
      try {
        const result =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.instituteData.instituteId,
          });
        this.examSettings = result.result[0];
      } catch (error) {
        console.error("error ", error);
      }
    },
    async getSemesterDetails() {
      try {
        const result =
          await this.SemesterRepositoryInstance.getSemestersBySemId({
            instituteId: this.instituteData.instituteId,
            semId: this.userData.semId,
          });
        const res = await result.filter(
          (item) =>
            item.department === this.userData.department &&
            item.courseYear === this.userData.courseYear &&
            item?.division === this.userData?.division
        );
        this.semName = res[0].semName;
        this.allCourseYears = result.map((item) => item.courseYear);
        this.selectedCourseYear = this.allCourseYears[0];
        await this.getSubjectsOfACourseYear();
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectsOfACourseYear() {
      try {
        this.subjectsMappingArray = [];
        this.SubjectDetailsOfACourseYear = [];
        const result =
          await this.subjectsRepositoryInstance.getSubjectsOfACourseYear({
            instituteId: this.instituteData.instituteId,
            semId: this.userData.semId,
            department: this.userData.department,
            courseYear: this.userData.courseYear,
          });
        this.SubjectDetailsOfACourseYear = result;
        result.forEach((item) => {
          this.subjectsMappingArray.push({
            subjectId: item.subjectId,
            subjectName: item.subjectName,
            subjectCode: item.subjectCode,
            credits: item.credits,
            totalCieMarks: item.totalCieMarks,
            totalSeeMarks: item.totalSeeMarks,
          });
        });
        this.subjectsMappingArray.unshift({
          subjectId: "all",
          subjectName: "All",
        });
        this.selectedSubjectId = "all"
        this.selectedSubjectId = this.subjectsMappingArray[0].subjectId;
        this.handleSubjectChange(this.selectedSubjectId);
      } catch (error) {
        console.error(error);
      }
    },
    async getAllQuickTestAnswers(examIds) {
      try {
        const response = await apiV3.postRequest("/exam/getAllAnswers", {
          studentId: this.userData.uId,
          examIds: examIds,
        });
        this.allQuickTestAnswers = response;
      } catch (error) {
        console.error(error);
      }
    },
    async getAllAssignments() {
      try {
        const objToPush = {
          instituteId: this.instituteData.instituteId,
          semId: this.userData.semId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
        };
        this.allAssignments =
          await this.assignmentRepositoryInstance.getAssignmentsOfACourseYear(
            objToPush
          );
      } catch (error) {
        this.allAssignments = [];
        console.error(error);
      }

      try {
        const objToPush = {
          instituteId: this.instituteData.instituteId,
          semId: this.userData.semId,
          uId: this.userData.uId,
        };
        this.allSubmittedAssignments =
          await this.assignmentUserRepositoryInstance.getSubmittedAssignmentsOfAnUser(
            objToPush
          );
      } catch (error) {
        this.allSubmittedAssignments = [];
        console.error(error);
      }
    },
    calculateIndividualExamResult(exam, currentSubject) {
      let totalMarks = 0;
      let totalObtainedMarks = 0;
      if (exam.examId) {
        if (
          Array.isArray(exam.examSubjectsAndQuestions) &&
          exam.examSubjectsAndQuestions.length > 0 &&
          exam.examStatus === "Published"
        ) {
          if (
            exam.applicableSubjects.includes(currentSubject.subjectId) &&
            this.userData.subjects.includes(currentSubject.subjectId)
          ) {
            exam.examSubjectsAndQuestions.map((subject) => {
              if (subject.subjectId === currentSubject.subjectId) {
                let student;
                if (subject.studentData) {
                  student = subject.studentData.filter(
                    (e) => e.studentId === this.userData.uId
                  )[0];
                }
                if (student) {
                  if (student.obtainedMarks) {
                    totalObtainedMarks += Number(student.obtainedMarks);
                  }
                }

                const [subjectDetail] = exam.subjectDetails.filter(
                  (e) => e.subjectId === currentSubject.subjectId
                );
                if (subjectDetail) {
                  if (subjectDetail.totalMarks) {
                    totalMarks += Number(subjectDetail.totalMarks);
                  }
                }
              }
            });
          }
        }
      } else {
        if (
          currentSubject.subjectId === exam.subjectId &&
          this.userData.subjects.includes(currentSubject.subjectId)
        ) {
          const submittedAssignment = this.allSubmittedAssignments.filter(
            (item) => item.assignmentId === exam.assignmentId
          )[0];
          totalMarks += Number(exam.totalObtainableMarks);
          if (submittedAssignment && submittedAssignment.marksObtained) {
            totalObtainedMarks += Number(submittedAssignment.marksObtained);
          }
        }
      }
      return { totalObtainedMarks, totalMarks };
    },
    calculateStudentsResult(subjects) {
            this.allStudentPercentageWithSubjectId = {}
            // for (let si = 0; si < this.semesterStudents.length; si++) {
                const currentStudent = this.userData;

                let subjectMarks = []
                for (let subIn = 0; subIn < this.filteredSubjects.length; subIn++) {
                    const currentSubject = this.filteredSubjects[subIn];
                    if (currentStudent.subjects.includes(currentSubject.subjectId)) {
                        // calculate if the subject has grouped exams
                        if (currentSubject?.groupedExams?.length > 0) {
                            const result = this.calculateMarksOfASubject(currentStudent.uId, currentSubject.subjectId)
                            if (Object.keys(this.allStudentPercentageWithSubjectId).includes(result.subjectId)) {

                                this.allStudentPercentageWithSubjectId[result.subjectId]['allStudentPercentage'].push(Number(result.percentage))
                            } else {
                                this.allStudentPercentageWithSubjectId[result.subjectId] = {
                                    allStudentPercentage: [Number(result.percentage)],
                                    totalMarks: result.totalMarks,
                                }
                            }

                            if (result.totalMarks > 0) {
                                subjectMarks.push(result)
                            }
                        }
                    }
                }
                currentStudent['subjectMarks'] = subjectMarks
            // }

            if (this.examSettings.gradingSystem !== "absolute") {
                this.calculateSubjectWiseRange()
            }

            // for (let si = 0; si < this.semesterStudents.length; si++) {
                let subjectDetailsArray = []
                let totalCreditPoints = 0
                let totalCredits = 0
                let passedSubjectCount = 0
                let obtainedMarksSum = 0
                let totalMarksSum = 0
                if (currentStudent?.subjectMarks.length > 0) {
                    if (this.examSettings.systemPreference === "cbs") {
                        if (this.examSettings.gradingSystem === "absolute") {
                            // let subjectDetailsArray = []
                            currentStudent?.subjectMarks?.map((subject) => {
                                if (subject.isValidMarks === true) {
                                    obtainedMarksSum += subject.totalObtainedMarks
                                    totalMarksSum += subject.totalMarks
                                    let percentage = numOps.calculatePercentage(subject.totalObtainedMarks, subject.totalMarks)
                                    let internalPercentage = numOps.calculatePercentage(subject.totalObtainedInternalMarks, subject.totalInternalMarks)
                                    let externalPercentage = numOps.calculatePercentage(subject.totalObtainedExternalMarks, subject.totalExternalMarks)

                                    // let subjectCredits = subjects.filter(sub => sub.subjectId === subject.subjectId)[0].credits
                                    let subj = subjects?.filter(sub => sub.subjectId === subject.subjectId)[0]
                                    if (this.examSettings.selectedCieSeePreference === "yes") {
                                        if (subj.isSeparatePassing) {
                                            if (internalPercentage >= this.examSettings.minimumCiePercentage &&
                                                externalPercentage >= this.examSettings.minimumSeePercentage) {

                                                // if (percentage >=
                                                //     this.examSettings.lowestPercentageOfPassing) {
                                                const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                                                subject['obtainedCredits'] = subj.credits
                                                subject['obtainedGrade'] = gradeLetter
                                                subject['gradePoints'] = gradePoint
                                                subject['creditPoints'] = subj.credits * gradePoint
                                                // } else {
                                                //     subject['obtainedCredits'] = 0
                                                //     subject['obtainedGrade'] = "F"
                                                //     subject['gradePoints'] = 0
                                                //     subject['creditPoints'] = 0
                                                // }
                                            } else {
                                                subject['obtainedCredits'] = 0
                                                subject['obtainedGrade'] = "F"
                                                subject['gradePoints'] = 0
                                                subject['creditPoints'] = 0
                                            }
                                        } else {
                                            if (percentage >=
                                                this.examSettings.lowestPercentageOfPassing) {
                                                const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                                                subject['obtainedGrade'] = gradeLetter
                                                subject['obtainedCredits'] = subj.credits
                                                subject['gradePoints'] = gradePoint
                                                subject['creditPoints'] = subj.credits * gradePoint
                                            } else {
                                                subject['obtainedCredits'] = 0
                                                subject['obtainedGrade'] = "F"
                                                subject['gradePoints'] = 0
                                                subject['creditPoints'] = 0
                                            }
                                        }
                                    } else {
                                        if (percentage >=
                                            this.examSettings.lowestPercentageOfPassing) {
                                            const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                                            subject['obtainedGrade'] = gradeLetter
                                            subject['obtainedCredits'] = subj.credits
                                            subject['gradePoints'] = gradePoint
                                            subject['creditPoints'] = subj.credits * gradePoint

                                        }
                                    }
                                    if (subject.obtainedGrade !== ("" || "F")) {
                                        passedSubjectCount++
                                    }
                                    totalCreditPoints += subject.creditPoints
                                    totalCredits += subject.obtainedCredits
                                    subjectDetailsArray.push(subject)
                                }
                            })
                        } else {
                            currentStudent?.subjectMarks?.map((subject) => {
                                if (subject.isValidMarks === true) {
                                    obtainedMarksSum += subject.totalObtainedMarks
                                    totalMarksSum += subject.totalMarks

                                    let percentage = numOps.calculatePercentage(subject.totalObtainedMarks, subject.totalMarks)
                                    let internalPercentage = numOps.calculatePercentage(subject.totalObtainedInternalMarks, subject.totalInternalMarks)
                                    let externalPercentage = numOps.calculatePercentage(subject.totalObtainedExternalMarks, subject.totalExternalMarks)

                                    let subj = subjects?.filter(sub => sub.subjectId === subject.subjectId)[0]
                                    if (this.examSettings.selectedCieSeePreference === "yes") {
                                        if (subj.isSeparatePassing) {
                                            if (internalPercentage >= this.examSettings.minimumCiePercentage &&
                                                externalPercentage >= this.examSettings.minimumSeePercentage) {

                                                // if (percentage >= this.examSettings.lowestPercentageOfPassing) {
                                                const { gradeLetter, gradePoint } = this.assignGradeForRelative(subject.subjectId, percentage)

                                                subject['obtainedCredits'] = subj.credits
                                                subject['obtainedGrade'] = gradeLetter
                                                subject['gradePoints'] = gradePoint
                                                subject['creditPoints'] = subj.credits * gradePoint
                                            } else {
                                                subject['obtainedCredits'] = 0
                                                subject['obtainedGrade'] = "F"
                                                subject['gradePoints'] = 0
                                                subject['creditPoints'] = 0
                                            }
                                        } else {
                                            if (percentage >=
                                                this.examSettings.lowestPercentageOfPassing) {
                                                const { gradeLetter, gradePoint } = this.assignGradeForRelative(subj.subjectId, percentage)

                                                subject['obtainedGrade'] = gradeLetter
                                                subject['obtainedCredits'] = subj.credits
                                                subject['gradePoints'] = gradePoint
                                                subject['creditPoints'] = subj.credits * gradePoint
                                            } else {
                                                subject['obtainedCredits'] = 0
                                                subject['obtainedGrade'] = "F"
                                                subject['gradePoints'] = 0
                                                subject['creditPoints'] = 0
                                            }
                                        }
                                    } else {
                                        if (percentage >=
                                            this.examSettings.lowestPercentageOfPassing) {
                                            const { gradeLetter, gradePoint } = this.assignGradeForRelative(subj.subjectId, percentage)

                                            subject['obtainedGrade'] = gradeLetter
                                            subject['obtainedCredits'] = subj.credits
                                            subject['gradePoints'] = gradePoint
                                            subject['creditPoints'] = subj.credits * gradePoint
                                        } else {
                                            subject['obtainedCredits'] = 0
                                            subject['obtainedGrade'] = "F"
                                            subject['gradePoints'] = 0
                                            subject['creditPoints'] = 0
                                        }
                                    }
                                    if (subject.obtainedGrade !== ("" || "F")) {
                                        passedSubjectCount++
                                    }
                                    totalCreditPoints += subject.creditPoints
                                    totalCredits += subject.obtainedCredits
                                    subjectDetailsArray.push(subject)
                                }
                            })
                        }
                        let percentage = numOps.calculatePercentage(obtainedMarksSum, totalMarksSum)
                        currentStudent.semesterSubjectMarks = subjectDetailsArray
                        // let sgpa = this.calculateSGPA(passedSubjectCount, currentStudent.semesterSubjectMarks.length, totalCreditPoints, totalCredits)
                        // currentStudent['finalSgpa'] = sgpa
                        currentStudent['finalPercentage'] = percentage

                    }
                }
            // }
            // this.removeUnnecessaryFields()
            this.$set(this, 'studentExamsData', currentStudent);
            // this.studentExamsData = currentStudent
        },
        calculateMarksOfASubject(studentId, subjectId) {
            let totalMarks = 0
            let totalObtainedMarks = 0
            let totalInternalMarks = 0
            let totalExternalMarks = 0
            let totalObtainedInternalMarks = 0
            let totalObtainedExternalMarks = 0
            let examMarks = []
            let isValidMarks = true
            for (let index = 0; index < this.finalExamsToConsiderForResult.length; index++) {
                const currentExam = this.finalExamsToConsiderForResult[index];

                currentExam?.examSubjectsAndQuestions?.map((subject) => {
                    let regex = /^(\d+(\.\d+)?|\.\d+)$/;

                    if (subject.subjectId === subjectId) {
                        let currentStudent = subject?.examMarks?.filter(stud => stud.uId === studentId)[0]
                        if (currentStudent) {
                            if (!regex.test(currentStudent.totalMarksObtained)) {
                                let abc = this.handelCustomMarks(currentStudent, subject, subjectId, studentId, currentExam.examId)
                                return abc
                            }
                            let objToPush = {
                                examId: currentExam.examId,
                                marks: currentStudent.totalMarksObtained

                            }
                            totalMarks += Number(subject.totalMarks)
                            totalObtainedMarks += currentStudent.totalMarksObtained
                                ? Number(currentStudent.totalMarksObtained
                                ) : 0

                            if (currentExam.examFormat === "Formative") {
                                totalInternalMarks += Number(subject.totalMarks)
                                totalObtainedInternalMarks += currentStudent.totalMarksObtained
                                    ? Number(currentStudent.totalMarksObtained
                                    ) : 0
                            } else {
                                totalExternalMarks += Number(subject.totalMarks)
                                totalObtainedExternalMarks += currentStudent.totalMarksObtained
                                    ? Number(currentStudent.totalMarksObtained
                                    ) : 0
                            }
                            examMarks.push(objToPush)
                        }
                    }
                })
            }

            let percentage = numOps.calculatePercentage(totalObtainedMarks, totalMarks)
            let internalPercentage = numOps.calculatePercentage(totalObtainedInternalMarks, totalInternalMarks)
            let externalPercentage = numOps.calculatePercentage(totalObtainedExternalMarks, totalExternalMarks)
            return {
                totalInternalMarks,
                percentage,
                internalPercentage,
                externalPercentage,
                totalObtainedInternalMarks,
                totalExternalMarks,
                totalObtainedExternalMarks,
                totalObtainedMarks,
                totalMarks,
                subjectId,
                examMarks,
                isValidMarks
            }
        },
        async filterExams(type) {
          // if(paramSubjectId){
          //   this.filteredSubjects = this.SubjectDetailsOfACourseYear.filter(item => item.department === this.userData.department && item.courseYear === this.userData.courseYear && item.subjectId === paramSubjectId)
          // } else {
            this.filteredSubjects = this.SubjectDetailsOfACourseYear?.filter(item => item.department === this.userData.department && item.courseYear === this.userData.courseYear)
          // }
            let uniqueIdsSet = new Set();
            // Iterate over each object in the array
            this.filteredSubjects.forEach(item => {
                // Iterate over each groupedExams id in the current object
                item.groupedExams.forEach(id => {
                    // Add the id to the uniqueIdsSet
                    uniqueIdsSet.add(id);
                });
            });

            // Convert the set to an array (if needed)
            let uniqueIdsArray = Array.from(uniqueIdsSet);
            if(type && type === 'primary') {
              this.finalExamsToConsiderForResult = this.allExams?.filter(exam => uniqueIdsArray.includes(exam.examId))
            } else {
              this.finalExamsToConsiderForResult = this.allExams
            }
            this.calculateStudentsResult(this.filteredSubjects)
            // await this.saveResult()
            // await this.fetchData()

        },
        async calculateSubjectWiseRange() {
            this.relativeGradeRangeSubjectWise = {}
            // let keysForSubjectId = Object.keys(this.allStudentPercentageWithSubjectId)
            // if (keysForSubjectId.length > 0) {
            //     keysForSubjectId.map((key) => {
            //         this.relativeGradeRangeSubjectWise[key] = []
            //         let count = this.examSettings?.gradeDetails?.length
            //         let arrForMArks = this.allStudentPercentageWithSubjectId[key]['allStudentPercentage']
            //         let maxPercentage = Math.max(...arrForMArks)
            //         let minPercentage = this.examSettings.lowestPercentageOfPassing ? this.examSettings.lowestPercentageOfPassing : 40
            //         let numberToReduce = ((Number(maxPercentage) - Number(minPercentage)) / count).toFixed(2)
            //         let arr = []
            //         let newValue;
            //         let value;
            //         for (let i = 0; i < count; i++) {
            //             let objForGrade = {}
            //             let min;
            //             if (i === 0) {
            //                 value = maxPercentage
            //             } else {
            //                 value = newValue
            //             }
            //             let tempObj = {
            //                 from: null,
            //                 to: null
            //             }
            //             min = value - numberToReduce
            //             newValue = (min - 0.01).toFixed(2)
            //             tempObj.from = numOps.roundOffUptoTwoDecimals(value)
            //             tempObj.to = numOps.roundOffUptoTwoDecimals(min)
            //             // objForGrade.maxRange = numOps.roundOffUptoTwoDecimals(value)
            //             // objForGrade.minRange = numOps.roundOffUptoTwoDecimals(min)
            //             objForGrade.gradeRange = tempObj
            //             objForGrade.gradeName = this.examSettings?.gradeDetails[i].gradeName
            //             objForGrade.gradePoints = Number(this.examSettings?.gradeDetails[i].gradePoints)
            //             objForGrade.gradeRemark = this.examSettings?.gradeDetails[i].gradeRemark
            //             arr.push(objForGrade)
            //         }
            //         this.relativeGradeRangeSubjectWise[key] = arr

            //     })
            // }
            let totalNumberOfGrades = this.examSettings.gradeDetails.length
            this.relativeGradeRangeSubjectWise = {};
            for (const [key, value] of Object.entries(this.allStudentPercentageWithSubjectId)) {
                let upperLimit = Math.max(...value.allStudentPercentage);
                let rangeDiff = numOps.roundOffUptoTwoDecimals(
                    (upperLimit - Number(this.examSettings.lowestPercentageOfPassing)) /
                    totalNumberOfGrades
                );
                let tempGradeDetails = [];
                // calculate grade range based on max obtained marks by a student for each subject
                for (let index = 0; index < totalNumberOfGrades; index++) {
                    let lowerLimit = numOps.roundOffUptoTwoDecimals(upperLimit - rangeDiff);
                    let tempGradeRange = {
                        from: lowerLimit,
                        to: upperLimit,
                    };
                    let tempGradeName = this.examSettings.gradeDetails[index].gradeName;
                    let tempGradePoints = this.examSettings.gradeDetails[index].gradePoints;
                    let tempGradeRemark = this.examSettings.gradeDetails[index].gradeRemark;
                    tempGradeDetails.push({
                        gradeName: tempGradeName,
                        gradePoints: tempGradePoints,
                        gradeRemark: tempGradeRemark,
                        gradeRange: tempGradeRange,
                    });
                    upperLimit = numOps.roundOffUptoTwoDecimals(lowerLimit - 0.01);
                }
                this.relativeGradeRangeSubjectWise[key] = tempGradeDetails; // subjectId: subjectGradeRanges
                this.relativeGradeRangeSubjectWise[key][totalNumberOfGrades - 1].gradeRange.from =
                    Number(this.examSettings.lowestPercentageOfPassing);
            }
            // await this.updateSubjectDetails()
        },
        async updateSubjectDetails() {
            let subjectsToUpdate = [];
            for (const [key, value] of Object.entries(this.relativeGradeRangeSubjectWise)) {
                let objToPush = {};
                objToPush.subjectId = key;
                objToPush.instituteId = this.selectedInstitute;
                objToPush.semId = this.userData.semId;
                objToPush.department = this.selectedDepartment;
                objToPush.courseYear = this.selectedCourseYear;
                objToPush.gradeRange = value;
                // value.reverse()
                subjectsToUpdate.push(objToPush);
            }
            try {
                // await this.subjectsRepositoryInstance.updateMultipleSubjectDetails(
                //     {
                //         subjectsToUpdate,
                //     }
                // );
            } catch (error) {
                console.error("error", error);
            }
        },
        assignGrade(marks) {
            let gradeLetter = ""
            let gradePoint = 0
            let tempGrade = this.examSettings.gradeDetails.filter(e => Number(marks) <= Number(e.gradeRange.from) && Number(marks) >= Number(e.gradeRange.to))[0]
            if (tempGrade) {
                gradeLetter = tempGrade.gradeName
                gradePoint = Number(tempGrade.gradePoints)
            } else {
                gradeLetter = "F"
                gradePoint = 0
            }
            return { gradeLetter, gradePoint }
        },
        assignGradeForRelative(subjectId, percentage) {
            let obj = {}
            this.relativeGradeRangeSubjectWise[subjectId]?.map((info) => {
                if (percentage >= info.gradeRange.from &&
                    percentage <= info.gradeRange.to) {
                    obj['gradeLetter'] = info.gradeName
                    obj['gradePoint'] = info.gradePoints
                }
            })
            return obj
        },
        handelCustomMarks(student, sub, subjectId, studentId, examId) {
            let obj = {
                totalInternalMarks: 0,
                percentage: 0,
                internalPercentage: 0,
                externalPercentage: 0,
                totalObtainedInternalMarks: 0,
                totalExternalMarks: 0,
                totalObtainedExternalMarks: 0,
                totalObtainedMarks: 0,
                totalMarks: 0,
                subjectId: sub.subjectId,
                examMarks: [],
                isValidMarks: false,
                obtainedGrade: 'F',
                obtainedCredits: 0,
                gradePoints: 0,
                creditPoints: 0
            }
            if (sub.subjectId === subjectId) {
                let currentStudent = sub.examMarks.filter(stud => stud.uId === studentId)[0]
                if (currentStudent) {
                    let objToPush = {
                        examId: examId,
                        marks: currentStudent.totalMarksObtained
                    }

                    obj.examMarks.push(objToPush)
                }
            }
            let allNicmarIns = ["d26eac93-150f-446d-8708-8ce794361957", "25738fb8-0205-4b7e-ace2-96f8f940bb8d", "2e1691e6-f0bb-446d-b080-e0173d44af4c", "860153bb-3a42-4101-b3fb-8f08f684db90", "8e38d185-7cb0-42ba-bb19-04fdfacf819d", "f32c10d6-f25d-44b5-9fcb-e35e33e2aec2"]
            if (allNicmarIns.includes(sub.instituteId)) {
                if (student.totalMarksObtained === 'P') {
                    obj.obtainedGrade = student.totalMarksObtained
                    obj.obtainedCredits = this.giveCreditsBySubId(sub.subjectId)
                }
                if (student.totalMarksObtained === 'NP') {
                    obj.obtainedGrade = student.totalMarksObtained
                }
            }
            return obj

        },
        giveCreditsBySubId(id) {
            let credits = 0
            this.allSubjectData.map((sub) => {
                if (sub.subjectId === id) {
                    credits = Number(sub.credits)
                }
            })
            return credits
        },
        calculateSGPA(
            passedSubjectCount,
            totalSubjectCount,
            totalCreditPoints,
            totalCredits
        ) {
            let finalSgpa = 0;
            if (passedSubjectCount === totalSubjectCount) {
                let tempSgpa = totalCreditPoints / totalCredits;
                if (isNaN(tempSgpa)) {
                    finalSgpa = 0;
                } else {
                    finalSgpa = numOps.roundOffUptoTwoDecimals(tempSgpa);
                }
            } else {
                finalSgpa = 0;
            }
            return finalSgpa;
        },
    async calculateResults() {
      this.resultReportData = [];
      this.isLoadingMagaeExams = true;
      this.xAxisData = [];
      this.yAxisData = [];
      // this.studentExam = [];
      this.allSubjectData = [];
      this.passingMarks = 0;
      this.sumOfObtainedMarksInAllExams = 0;
      this.totalMarksOfAllExams = 0;
      this.performanceStatus = "";
      let passedSubjectCount = 0;
      let arrayForExcelReport = [];
      if (this.examSettings.systemPreference === "percentage") {
        if (this.examSettings.selectedCieSeePreference === "no") {
          if (this.selectedSubjectId === "all") {
            this.allSubjectData = [];
            this.sumOfObtainedMarksInAllExams = 0;
            for (
              let index = 1;
              index < this.subjectsMappingArray?.length;
              index++
            ) {
              let objToPush = {};
              let tempPer = 0;
              let subjectExamCount = 0;
              let percentage;
              const currentSubject = this.subjectsMappingArray[index];
              if (this.userData.subjects.includes(currentSubject.subjectId)) {
                objToPush["subjectName"] = currentSubject.subjectName;
                this.xAxisData.push(currentSubject.subjectName);
                let tempTotalMarks = 0;
                let tempObtainedMarks = 0;

                for (
                  let index = 0;
                  index < this.filteredExams?.length;
                  index++
                ) {
                  const exam = this.filteredExams[index];

                  // check if any exam conducted for a subject or not
                  if (exam.examId) {
                    if (
                      exam.applicableSubjects.includes(currentSubject.subjectId)
                    ) {
                      subjectExamCount = 1;
                    }
                  } else {
                    if (exam.subjectId === currentSubject.subjectId) {
                      subjectExamCount = 1;
                    }
                  }

                  let result = this.calculateIndividualExamResult(
                    exam,
                    currentSubject
                  );

                  tempObtainedMarks += result.totalObtainedMarks;
                  this.sumOfObtainedMarksInAllExams +=
                    result.totalObtainedMarks;
                  tempTotalMarks += result.totalMarks;
                  this.totalMarksOfAllExams += result.totalMarks;
                  percentage = 0;
                  tempPer = (tempObtainedMarks / tempTotalMarks) * 100;
                  if (isNaN(tempPer)) {
                    percentage = 0;
                  } else {
                    percentage = tempPer;
                  }
                  objToPush["percentage"] = `${this.roundOffUpto2Decimals(
                    percentage
                  )}%`;
                  objToPush["totalMarks"] = `${this.roundOffUpto2Decimals(
                    tempObtainedMarks
                  )}/${this.roundOffUpto2Decimals(tempTotalMarks)}`;

                  if (this.examSettings.passingPercentageType === "overall") {
                    if (
                      Number(percentage) >=
                        Number(this.examSettings.overallPercentage) &&
                      percentage > 0
                    ) {
                      objToPush["status"] = "Pass";
                    } else {
                      objToPush["status"] = "Fail";
                    }
                  }
                }

                if (subjectExamCount === 1) {
                  this.yAxisData.push(this.roundOffUpto2Decimals(percentage));
                  this.allSubjectData.push(objToPush);
                }
              }
            }
          } else {
            this.filteredExams.forEach(async (exam) => {
              if (
                exam.applicableSubjects.includes(this.selectedSubjectId) &&
                this.userData.subjects.includes(this.selectedSubjectId)
              ) {
                if (
                  Array.isArray(exam.examSubjectsAndQuestions) &&
                  exam?.examSubjectsAndQuestions?.length > 0 &&
                  exam.examStatus === "Published"
                ) {
                  exam.examSubjectsAndQuestions.forEach((subject) => {
                    if (subject.subjectId === this.selectedSubjectId) {
                      const [student] = subject.studentData.filter(
                        (e) => e.studentId === this.userData.uId
                      );
                      const [subjectDetail] = exam.subjectDetails.filter(
                        (e) => e.subjectId === this.selectedSubjectId
                      );

                      if (subjectDetail && subjectDetail.totalMarks) {
                        this.totalMarksOfAllExams += Number(
                          subjectDetail.totalMarks
                        );
                      }
                      subject.examName = exam.name;
                      subject.examType = exam.type;
                      subject.subjectName = this.subjectsMappingArray.filter(
                        (item) => item.subjectId === this.selectedSubjectId
                      )[0].subjectName;
                      subject.startDate = exam.startDate ? exam.startDate : "-";
                      subject.endDate = exam.endDate ? exam.endDate : "-";
                      if (student) {
                        let percentage = 0;
                        let tempPercentage =
                          (student.obtainedMarks / student.total) * 100;
                        if (isNaN(tempPercentage)) {
                          percentage = 0;
                        } else {
                          percentage = tempPercentage;
                        }
                        if (student.obtainedMarks) {
                          this.sumOfObtainedMarksInAllExams += Number(
                            student.obtainedMarks
                          );
                        }
                        this.yAxisData.push(
                          this.roundOffUpto2Decimals(percentage)
                        );
                        this.xAxisData.push(exam.name);
                        subject.obtainedMarks = student.obtainedMarks
                          ? student.obtainedMarks
                          : 0;
                        subject.total = student.total ? student.total : "";
                      }
                      if (subjectDetail) {
                        subject.durationInMins = subjectDetail
                          ? subjectDetail.duration
                          : "-";
                        subject.passingMarks = subjectDetail.passingMarks
                          ? subjectDetail.passingMarks
                          : "-";
                        subject.examDate = subjectDetail
                          ? subjectDetail.selectedDate
                          : "-";
                        subject.examTime = subjectDetail
                          ? subjectDetail.selectedTime
                          : "-";
                      }
                      if (subject.passingMarks > 0) {
                        subject.status =
                          Number(subject.obtainedMarks) >=
                          Number(subject.passingMarks)
                            ? "Pass"
                            : "Fail";
                      }
                      // this.studentExam.push(subject);
                    }
                  });
                }
              }
            });
          }
        } else if (this.examSettings.selectedCieSeePreference === "yes") {
          if (this.selectedSubjectId === "all") {
            for (
              let index = 1;
              index < this.subjectsMappingArray?.length;
              index++
            ) {
              let count = 0;
              let objToPush = {};
              let tempPer = 0;
              let percentage;
              let status = "";
              const currentSubject = this.subjectsMappingArray[index];
              if (this.userData.subjects.includes(currentSubject.subjectId)) {
                objToPush["subjectName"] = currentSubject.subjectName;
                this.xAxisData.push(currentSubject.subjectName);
                let tempTotalMarks = 0;
                let tempObtainedMarks = 0;
                let subjectExamCount = 0;

                for (
                  let index = 0;
                  index < this.filteredSeeExams?.length;
                  index++
                ) {
                  const exam = this.filteredSeeExams[index];

                  if (exam.examId) {
                    if (
                      exam.applicableSubjects.includes(currentSubject.subjectId)
                    ) {
                      subjectExamCount = 1;
                    }
                  } else {
                    if (exam.subjectId) {
                      subjectExamCount = 1;
                    }
                  }

                  let result = this.calculateIndividualExamResult(
                    exam,
                    currentSubject
                  );

                  tempObtainedMarks += Number(result.totalObtainedMarks);
                  this.sumOfObtainedMarksInAllExams += Number(
                    result.totalObtainedMarks
                  );
                  tempTotalMarks += Number(result.totalMarks);
                  this.totalMarksOfAllExams += Number(result.totalMarks);
                  percentage = 0;
                  tempPer = (tempObtainedMarks / tempTotalMarks) * 100;
                  if (isNaN(tempPer)) {
                    percentage = 0;
                  } else {
                    percentage = tempPer;
                  }
                  objToPush["percentage"] = `${this.roundOffUpto2Decimals(
                    percentage
                  )}%`;
                  objToPush["totalMarks"] = `${this.roundOffUpto2Decimals(
                    tempObtainedMarks
                  )}/${this.roundOffUpto2Decimals(tempTotalMarks)}`;

                  if (this.examSettings.passingPercentageType === "overall") {
                    if (
                      Number(percentage) >=
                      Number(this.examSettings.seePassingPercentage)
                    ) {
                      status = "Pass";
                    } else {
                      status = "Fail";
                    }
                  }
                }
                if (status === "Pass") {
                  count++;
                }

                for (
                  let index = 0;
                  index < this.filteredCieExams?.length;
                  index++
                ) {
                  const exam = this.filteredCieExams[index];

                  if (exam.examId) {
                    if (
                      exam.applicableSubjects.includes(currentSubject.subjectId)
                    ) {
                      subjectExamCount = 1;
                    }
                  } else {
                    if (exam.subjectId) {
                      subjectExamCount = 1;
                    }
                  }

                  let result = this.calculateIndividualExamResult(
                    exam,
                    currentSubject
                  );

                  tempObtainedMarks += Number(result.totalObtainedMarks);
                  this.sumOfObtainedMarksInAllExams += Number(
                    result.totalObtainedMarks
                  );
                  tempTotalMarks += Number(result.totalMarks);
                  this.totalMarksOfAllExams += Number(result.totalMarks);
                  percentage = 0;
                  tempPer = (tempObtainedMarks / tempTotalMarks) * 100;
                  if (isNaN(tempPer)) {
                    percentage = 0;
                  } else {
                    percentage = tempPer;
                  }
                  objToPush["percentage"] = `${this.roundOffUpto2Decimals(
                    percentage
                  )}%`;
                  objToPush["totalMarks"] = `${this.roundOffUpto2Decimals(
                    tempObtainedMarks
                  )}/${this.roundOffUpto2Decimals(tempTotalMarks)}`;

                  if (this.examSettings.passingPercentageType === "overall") {
                    if (
                      Number(percentage) >=
                      Number(this.examSettings.ciePassingPercentage)
                    ) {
                      status = "Pass";
                    } else {
                      status = "Fail";
                    }
                  }
                }
                if (status === "Pass") {
                  count++;
                }
                if (percentage > 0) {
                  if (count === 2) {
                    if (percentage > this.examSettings.overallPercentage) {
                      objToPush["status"] = "Pass";
                    } else {
                      objToPush["status"] = "Fail";
                    }
                  } else {
                    objToPush["status"] = "Fail";
                  }
                }

                if (subjectExamCount === 1) {
                  this.xAxisData.push(this.roundOffUpto2Decimals(percentage));
                  this.allSubjectData.push(objToPush);
                }

                let objToPush = {}
            this.allSubjectHeader.map((item) => {
                objToPush[`${item.text}`] = subject[`${item.value}`]
              });
              arrayForExcelReport.push(objToPush)
              }
            }
          } else {
            this.handleSubjectSpecificCieSeeExamDetails();
          }
        }
      } else {
        if (this.selectedSubjectId === "all") {
          this.allSubjectHeader.splice(5);
          this.allSubjectHeader.push(
            {
              text: "Credits",
              value: "obtainedCredits",
            },
            {
              text: "Grade",
              value: "obtainedGrade",
            },
            {
              text: "Credit Point",
              value: "totalCreditPoints",
            },
            {
              text: "Status",
              value: "status",
              align: "center",
            }
          );
          this.studentSemesterDetails.semesterSubjectMarks.map((subject) => {
            const subjectDetails = this.subjectsMappingArray.filter(
              (item) => item.subjectId === subject.subjectId
            )[0];
            if (subjectDetails) {
              // let percentage = this.calculatePercentage(
              //   subject.obtainedMarks,
              //   subject.totalMarks
              // );
              subject["subjectCode"] = subjectDetails.subjectCode
              // this.sumOfObtainedMarksInAllExams += Number(subject.obtainedMarks)
              // this.totalMarksOfAllExams += Number(subject.totalMarks)
              subject["subjectName"] = subjectDetails.subjectName;
              // subject["percentage"] = `${percentage} %`;
              subject["status"] =
                subject.obtainedGrade === "F" ? "Fail" : "Pass";
              subject["totalCreditPoints"] = subject.gradePoints;
              // this.xAxisData.push(subjectDetails.subjectName);
              // this.yAxisData.push(this.roundOffUpto2Decimals(percentage));
            }

            // let objToPush = {}
            // this.allSubjectHeader.map((item) => {
            //     objToPush[`${item.text}`] = subject[`${item.value}`]
            //   });
              // arrayForExcelReport.push(objToPush)
          });
          this.allSubjectData = Array.isArray(this.studentSemesterDetails.semesterSubjectMarks)
            ? this.studentSemesterDetails.semesterSubjectMarks
            : [...this.studentSemesterDetails.semesterSubjectMarks];
        } else {
          this.handleSubjectSpecificCieSeeExamDetails();
        }
        this.resultReportData = [...arrayForExcelReport];
      }
      this.examOptions = {
        ...this.examOptions,
        xaxis: { categories: [...this.xAxisData] },
      };
      this.examSeries = [{ name: "", data: [...this.yAxisData] }];
      if (this.totalMarksOfAllExams > 0) {
        this.passingMarks =
          (Number(this.examSettings.overallPercentage) / 100) *
          this.totalMarksOfAllExams;
      }
      const tempAvg = this.roundOffUpto2Decimals(
        (this.sumOfObtainedMarksInAllExams / this.totalMarksOfAllExams) * 100
      );
      if (isNaN(tempAvg)) {
        this.overallPerformance = 0;
      } else {
        this.overallPerformance = tempAvg;
      }
      if (this.sumOfObtainedMarksInAllExams > this.passingMarks) {
        this.performanceStatus = "Pass";
      } else {
        this.performanceStatus = "Fail";
      }
      if (this.sumOfObtainedMarksInAllExams === 0) {
        this.performanceStatus = "-";
      }
      if (this.selectedSubjectId === "all") {
        for (let index = 0; index < this.allSubjectData?.length; index++) {
          const element = this.allSubjectData[index];
          if (element.status === "Pass") passedSubjectCount++;
        }
        if (passedSubjectCount !== this.allSubjectData?.length) {
          this.performanceStatus = "Fail";
          this.overallPerformance = "-";
        }
      }
      this.allSubjectData = Array.isArray(this.studentSemesterDetails.semesterSubjectMarks)
      ? this.studentSemesterDetails.semesterSubjectMarks
      : [...this.studentSemesterDetails.semesterSubjectMarks];
      // Create a map for quick lookups
      const semesterSubjectMarksMap = new Map();
      if (this.studentSemesterDetails.semesterSubjectMarks) {
        for (const mark of this.studentSemesterDetails.semesterSubjectMarks) {
          semesterSubjectMarksMap.set(mark.subjectId, mark);
        }
      }

      for (const subject of this.allSubjectData || []) {
        const mark = semesterSubjectMarksMap.get(subject.subjectId);
        if (mark) {
          subject.creditPoints = mark.creditPoints;
          subject.examMarks = mark.examMarks;
          subject.gradePoints = mark.gradePoints;
          subject.obtainedCredits = mark.obtainedCredits;
          subject.obtainedGrade = mark.obtainedGrade;
          subject.percentage = mark.percentage;
          subject.totalSeeObtainedMarks = mark.totalSeeObtainedMarks ? mark.totalSeeObtainedMarks : mark.totalObtainedExternalMarks;
          subject.totalCieObtainedMarks = mark.totalCieObtainedMarks ? mark.totalCieObtainedMarks : mark.totalObtainedInternalMarks;
          subject.totalMarks = mark.totalMarks
          subject.totalObtainedExternalMarks = mark.totalSeeObtainedMarks ? mark.totalSeeObtainedMarks : mark.totalObtainedExternalMarks;
          subject.totalObtainedInternalMarks = mark.totalCieObtainedMarks ? mark.totalCieObtainedMarks : mark.totalObtainedInternalMarks;
          subject.totalObtainedMarks = mark.obtainedMarks ? mark.obtainedMarks : mark.totalObtainedMarks
          subject.totalCreditPoints = mark.obtainedGradePoints || mark.creditPoints
          subject.status = subject.obtainedGrade !== ("F" || "NP") ? "Pass" : "Fail";
        } else {
          subject.creditPoints = '-';
          subject.examMarks = [];
          subject.gradePoints = "-";
          subject.obtainedCredits = "-";
          subject.obtainedGrade = mark?.obtainedGrade || "-";
          subject.percentage = "-";
          subject.totalSeeObtainedMarks = "-";
          subject.totalCieObtainedMarks = "-";
          subject.totalMarks = "-";
          subject.totalObtainedExternalMarks = "-";
          subject.totalObtainedInternalMarks = "-";
          subject.totalObtainedMarks = "-";
          subject.status = "-";
        }
      }
      this.isLoadingMagaeExams = false;
    },
    handleSubjectSpecificCieSeeExamDetails() {
      this.xAxisData = [];
      this.yAxisData = [];
      for (let index = 0; index < this.filteredCieExams.length; index++) {
        const exam = this.filteredCieExams[index];

        if (exam.examId) {
          if (
            exam.applicableSubjects.includes(this.selectedSubjectId) &&
            this.userData.subjects.includes(this.selectedSubjectId)
          ) {
            if (
              Array.isArray(exam.examSubjectsAndQuestions) &&
              exam.examSubjectsAndQuestions.length > 0 &&
              exam.examStatus === "Published"
            ) {
              exam.examSubjectsAndQuestions.forEach((subject) => {
                if (subject.subjectId === this.selectedSubjectId) {
                  let student;
                  if (subject.studentData) {
                    student = subject.studentData.filter(
                      (e) => e.studentId === this.userData.uId
                    )[0];
                  }
                  const [subjectDetail] = exam.subjectDetails.filter(
                    (e) => e.subjectId === this.selectedSubjectId
                  );

                  if (subjectDetail && subjectDetail.totalMarks) {
                    this.totalMarksOfAllExams += Number(
                      subjectDetail.totalMarks
                    );
                  }
                  subject.examName = exam.name;
                  subject.examType = exam.type;
                  subject.subjectName = this.subjectsMappingArray.filter(
                    (item) => item.subjectId === this.selectedSubjectId
                  )[0].subjectName;
                  subject.startDate = exam.startDate ? exam.startDate : "-";
                  subject.endDate = exam.endDate ? exam.endDate : "-";
                  if (student) {
                    let percentage = 0;
                    let tempPercentage =
                      (student.obtainedMarks / student.total) * 100;
                    if (isNaN(tempPercentage)) {
                      percentage = 0;
                    } else {
                      percentage = tempPercentage;
                    }
                    if (student.obtainedMarks) {
                      this.sumOfObtainedMarksInAllExams += Number(
                        student.obtainedMarks
                      );
                    }
                    this.yAxisData.push(this.roundOffUpto2Decimals(percentage));
                    this.xAxisData.push(exam.name);
                    subject.obtainedMarks = student.obtainedMarks
                      ? student.obtainedMarks
                      : 0;
                    subject.total = student.total ? student.total : "";
                  }
                  if (subjectDetail) {
                    subject.durationInMins = subjectDetail
                      ? subjectDetail.duration
                      : "-";
                    subject.passingMarks = subjectDetail.passingMarks
                      ? subjectDetail.passingMarks
                      : "-";
                    subject.examDate = subjectDetail
                      ? subjectDetail.selectedDate
                      : "-";
                    subject.examTime = subjectDetail
                      ? subjectDetail.selectedTime
                      : "-";
                  }
                  if (subject.passingMarks > 0 && subject.obtainedMarks) {
                    subject.status =
                      Number(subject.obtainedMarks) >=
                      Number(subject.passingMarks)
                        ? "Pass"
                        : "Fail";
                  }
                  if (subject.studentData.some((data) => data.studentId === this.userData.uId)) {
                    // this.studentExam.push(subject);
                  }
                }
              });
            }
          }
        } else {
          let currentStudent = {};
          const submittedAssignment = this.allSubmittedAssignments.filter(
            (item) => item.assignmentId === exam.assignmentId
          )[0];
          if (exam.subjectId === this.selectedSubjectId) {
            this.totalMarksOfAllExams += Number(exam.totalObtainableMarks);
            if (exam.assignmentType === "Rubric") {
              if (exam.studentMarks.length > 0) {
                currentStudent = exam.studentMarks.filter(
                  (item) => item.uId === this.userData.uId
                )[0];
              }
            } else {
              currentStudent = submittedAssignment;
            }
            if (currentStudent && currentStudent.marksObtained) {
              this.sumOfObtainedMarksInAllExams += Number(
                currentStudent.marksObtained
              );

              let percentage = 0;
              let tempPercentage =
                (currentStudent.marksObtained / exam.totalObtainableMarks) *
                100;
              if (isNaN(tempPercentage)) {
                percentage = 0;
              } else {
                percentage = tempPercentage;
              }
              this.xAxisData.push(exam.title);
              this.yAxisData.push(this.roundOffUpto2Decimals(percentage));
              // this.studentExam.push({
              //   examName: exam.title,
              //   examDate: convertDateTime(exam.deadlineForSubmission),
              //   subjectName: this.subjectsMappingArray.filter(
              //     (item) => item.subjectId === exam.subjectId
              //   )[0].subjectName,
              //   durationInMins: "-",
              //   passingMarks: 0,
              //   obtainedMarks: currentStudent.marksObtained,
              //   total: exam.totalObtainableMarks,
              //   status: "Pass",
              // });
            }
          }
        }
      }

      for (let index = 0; index < this.filteredSeeExams.length; index++) {
        const exam = this.filteredSeeExams[index];

        if (exam.examId) {
          if (
            exam.applicableSubjects.includes(this.selectedSubjectId) &&
            this.userData.subjects.includes(this.selectedSubjectId)
          ) {
            if (
              Array.isArray(exam.examSubjectsAndQuestions) &&
              exam.examSubjectsAndQuestions.length > 0 &&
              exam.examStatus === "Published"
            ) {
              exam.examSubjectsAndQuestions.forEach((subject) => {
                if (subject.subjectId === this.selectedSubjectId) {
                  const [student] = subject.studentData.filter(
                    (e) => e.studentId === this.userData.uId
                  );
                  const [subjectDetail] = exam.subjectDetails.filter(
                    (e) => e.subjectId === this.selectedSubjectId
                  );

                  if (subjectDetail && subjectDetail.totalMarks) {
                    this.totalMarksOfAllExams += Number(
                      subjectDetail.totalMarks
                    );
                  }
                  subject.examName = exam.name;
                  subject.examType = exam.type;
                  subject.subjectName = this.subjectsMappingArray.filter(
                    (item) => item.subjectId === this.selectedSubjectId
                  )[0].subjectName;
                  subject.startDate = exam.startDate ? exam.startDate : "-";
                  subject.endDate = exam.endDate ? exam.endDate : "-";
                  if (student) {
                    let percentage = 0;
                    let tempPercentage =
                      (student.obtainedMarks / student.total) * 100;
                    if (isNaN(tempPercentage)) {
                      percentage = 0;
                    } else {
                      percentage = tempPercentage;
                    }
                    if (student.obtainedMarks) {
                      this.sumOfObtainedMarksInAllExams += Number(
                        student.obtainedMarks
                      );
                    }
                    this.yAxisData.push(this.roundOffUpto2Decimals(percentage));
                    this.xAxisData.push(exam.name);
                    subject.obtainedMarks = student.obtainedMarks
                      ? student.obtainedMarks
                      : 0;
                    subject.total = student.total ? student.total : "";
                  }
                  if (subjectDetail) {
                    subject.durationInMins = subjectDetail
                      ? subjectDetail.duration
                      : "-";
                    subject.passingMarks = subjectDetail.passingMarks
                      ? subjectDetail.passingMarks
                      : "-";
                    subject.examDate = subjectDetail
                      ? subjectDetail.selectedDate
                      : "-";
                    subject.examTime = subjectDetail
                      ? subjectDetail.selectedTime
                      : "-";
                  }
                  if (subject.passingMarks > 0 && subject.obtainedMarks) {
                    subject.status =
                      Number(subject.obtainedMarks) >=
                      Number(subject.passingMarks)
                        ? "Pass"
                        : "Fail";
                  }
                  if (subject.studentData.some((data) => data.studentId === this.userData.uId)) {
                    // this.studentExam.push(subject);
                  }
                }
              });
            }
          }
        } else {
          let currentStudent = {};
          const submittedAssignment = this.allSubmittedAssignments.filter(
            (item) => item.assignmentId === exam.assignmentId
          )[0];
          if (exam.subjectId === this.selectedSubjectId) {
            this.totalMarksOfAllExams += Number(exam.totalObtainableMarks);
            if (exam.assignmentType === "Rubric") {
              if (exam.studentMarks.length > 0) {
                currentStudent = exam.studentMarks.filter(
                  (item) => item.uId === this.userData.uId
                )[0];
              }
            } else {
              currentStudent = submittedAssignment;
            }
            if (currentStudent && currentStudent.marksObtained) {
              this.sumOfObtainedMarksInAllExams += Number(
                currentStudent.marksObtained
              );

              let percentage = 0;
              let tempPercentage =
                (currentStudent.marksObtained / exam.totalObtainableMarks) *
                100;
              if (isNaN(tempPercentage)) {
                percentage = 0;
              } else {
                percentage = tempPercentage;
              }
              this.xAxisData.push(exam.title);
              this.yAxisData.push(this.roundOffUpto2Decimals(percentage));

              // this.studentExam.push({
              //   examName: exam.title,
              //   examDate: convertDateTime(exam.deadlineForSubmission),
              //   subjectName: this.subjectsMappingArray.filter(
              //     (item) => item.subjectId === exam.subjectId
              //   )[0].subjectName,
              //   durationInMins: "-",
              //   passingMarks: 0,
              //   obtainedMarks: currentStudent.marksObtained,
              //   total: exam.totalObtainableMarks,
              //   status: "Pass",
              // });
            }
          }
        }
      }
    },
    calculatePercentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
    async fetchExams() {
      const quickTestIds = [];
      try {
        this.isLoadingMagaeExams = true;
        this.isLoadingExams = true;
        if (
          this.instituteData.config &&
          this.instituteData.config.exams === "IN_HOUSE"
        ) {
          const response = await apiV3.getRequest("/exam/all", {
            category: "quick_exam",
            semId: this.userData.semId,
            studentId: this.userData.uId,
          });
          if (this.userData.batch && this.userData.batch !== "") {
            let dummy = [];
            if (response.exams.length > 0) {
              dummy = response.exams.filter(
                (data) => data.batch === this.userData.batch || data.division === this.userData.division
              );
            }
            response.exams = dummy;
          }
          for (let index = 0; index < response.exams.length; index++) {
            const element = response.exams[index];
            quickTestIds.push(element._id);
          }
          await this.getAllQuickTestAnswers(quickTestIds);
          await this.processQuickTests(response.exams);
          return;
        }
        const candidateID = this.userData.examCandidateID;
        const data = {
          studentId: candidateID,
        };
        this.quickTestForEklvyaNew = [];
        let response = [];
        response = await this.examRepositoryInstance.getExams(data);
        this.quickTestForEklvyaNew = [...response.results];
        this.exams = response.results;
        // const givenDateTime = new Date(givenDate);
        this.quickTestForEklvyaNew.map((data) => {
          data["isExamUpcoming"] = false;
          const givenDate = data.ScheduleEndDate;
          const givenDateTime = moment(givenDate, "MMM DD YYYY h:mmA");
          // Get the current date and time as a JavaScript Date object
          const currentDate = moment();
          // Compare the two dates
          if (givenDateTime.isBefore(currentDate)) {
          } else if (givenDateTime.isAfter(currentDate)) {
            data["isExamUpcoming"] = true;
          } else {
          }
        });
        this.processExams(response.results);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoadingExams = false;
      }
    },
    async getAllExamDetails() {
      try {
        // const result = await apiV3.getRequest("/manageExam/getAllExamDetails", {
        //   instituteId: this.instituteData.instituteId,
        //   semId: this.userData.semId,
        // });
        const examData = await this.examRepositoryInstance.getExamsAndSubjectAndQuestionDetails({
          instituteId: this.instituteData.instituteId,
          semId: this.userData.semId
        });
        this.allExams = examData;
        await this.filterExams()
        for (let index = 0; index < this.allExams?.length; index++) {
          const exam = this.allExams[index];
          if (
            exam.department === this.userData.department &&
            exam.courseYear === this.userData.courseYear
          ) {
            exam['formattedPublishDate'] = moment.utc(exam.publishDate).utcOffset('+05:30').format('DD-MM-YYYY')
            exam['formattedDueDate'] = moment.utc(exam.dueDate).utcOffset('+05:30').format('DD-MM-YYYY')
            // if(new Date() <= new Date(exam.startDate) && exam.examFormPublished && exam.studentsAllowedToFillExamForm.includes(this.userData.uId)) {
            if (exam.examFormDetails) {
              const result = exam?.examFormDetails?.filter(
                (item) => item.uId === this.userData.uId
              )[0];
              if (result) {
                exam.feeStatus = result.feeStatus ? "Paid" : "Unpaid";
                exam.approvalStatus = result.approvalStatus
                  ? "Approved"
                  : "Unapproved";
              }
            } else {
              exam.feeStatus = "Unpaid";
              exam.approvalStatus = "Unapproved";
            }
            this.currentExams.push(exam);
            // }
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async processQuickTests(exams) {
      const quickTest = [];
      const now = new Date();

      for await (const test of exams) {
        let state = "";
        let hasSubmitted = false;
        const examStartTime = parseISO(test.dateTimeUTC);

        if (isFuture(examStartTime)) {
          state = "pending";
          quickTest.push({ ...test, state, hasSubmitted });
          continue;
        }
        const endTime = addMinutes(examStartTime, test.durationInMins);
        if (compareAsc(endTime, now) === -1 || compareAsc(endTime, now) === 0) {
          state = "conducted";
          hasSubmitted = false;
          const response = this.allQuickTestAnswers.filter(
            (item) => item.examId === test._id
          );
          this.allAttemptAnswers = response;
          if (test.isreAttemptAllowed && response?.length === 3) {
            for (let i = 0; i < response.length; i++) {
              test.totalMarksObtained = 0 + response[i].totalMarksObtained;
              test.totalOutOf = 0 + response[i].totalOutOf;
            }
            test.totalMarks = test.totalOutOf;
            hasSubmitted = true;
          } else if (test.isreAttemptAllowed && response?.length === 2) {
            for (let i = 0; i < response.length; i++) {
              test.totalMarksObtained = 0 + response[i].totalMarksObtained;
              test.totalOutOf = 0 + response[i].totalOutOf;
            }
            test.totalMarks = test.totalOutOf;
            hasSubmitted = true;
          } else if (test.isreAttemptAllowed && response?.length === 1) {
            for (let i = 0; i < response.length; i++) {
              test.totalMarksObtained = 0 + response[i].totalMarksObtained;
              test.totalOutOf = 0 + response[i].totalOutOf;
            }
            test.totalMarks = test.totalOutOf;
            hasSubmitted = true;
          } else if (!test.isreAttemptAllowed && response?.length === 1) {
            hasSubmitted = true;
            const { totalMarksObtained, totalOutOf } = response[0];
            test.totalMarks = totalOutOf;
            test.totalMarksObtained = totalMarksObtained;
            test.totalOutOf = totalOutOf;
          }
          quickTest.push({ ...test, state, hasSubmitted, response });
          continue;
        }
        const isOngoing = isWithinInterval(now, {
          start: examStartTime,
          end: endTime,
        });
        if (isOngoing) {
          state = "ongoing";
          hasSubmitted = false;
          const response = this.allQuickTestAnswers.filter(
            (item) => item.examId === test._id
          );
          if (test.isreAttemptAllowed && response?.length === 3) {
            hasSubmitted = true;
          } else if (!test.isreAttemptAllowed && response?.length === 1) {
            const { totalMarksObtained, totalOutOf } = response[0];
            test.totalMarks = totalOutOf;
            hasSubmitted = true;
          }
          quickTest.push({ ...test, state, hasSubmitted });
          continue;
        }
      }
      this.quickTest = quickTest;
    },
    async getExamAnswers(examId) {
      try {
        const response = await apiV3.getRequest("/exam/answers", {
          studentId: this.userData.uId,
          examId,
        });
        return response;
      } catch (e) {
        return [];
      }
    },
    openLink(URL) {
      window.open(URL);
    },
    async processExams(exams) {
      this.isLoadingExamsInfo = true;
      let promises = [];
      if (exams) {
        exams.forEach((exam) => {
          promises.push(
            this.getDetailsOfAnExam({
              examId: exam._id,
              studentId: this.userData.uId,
            })
          );
        });
      }
      promises = await Promise.allSettled(promises);
      if (promises) {
        promises.forEach(async (exam) => {
          if (exam.value && exam.value.examType === "Quick Test") {
            let obj = {
              instituteId: exam.value.instituteId,
              semId: exam.value.semId,
              examId: exam.value.examId,
            };
            this.quickTest.push(exam.value);
          } else {
            this.exams.push(exam.value);
          }
        });
      }
      for (let i = 0; i < promises.length; i++) {
        this.items.push(promises[i].value);
      }
      this.isLoadingExamsInfo = true;
    },
    async getDetailsOfAnExam({ examId, studentId }) {
      const objToReturn = examId;
      // Call Api to get Subject Name and Type
      if (this.subjectsMapping[examId.subjectId]) {
        objToReturn.subjectName =
          this.subjectsMapping[examId.subjectId].subjectName;
        objToReturn.subjectType =
          this.subjectsMapping[examId.subjectId].subjectType;
        try {
          const examDetails =
            await this.examRepositoryInstance.getDetailsOfAnExam(examId);
          if (examDetails.name) {
            objToReturn.name = examDetails.name;
          }
          if (examDetails.type) {
            objToReturn.examType = examDetails.type;
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        objToReturn.subjectName = "-";
        objToReturn.subjectType = "-";
        objToReturn.examType = "-";
      }
      // Call Api to get Exam Type
      return objToReturn;
    },
    async getExamsOfACriteria() {
      const data = {
        instituteId: this.userData.instituteId,
        semId: this.userData.semId,
        department: this.userData.department,
      };
      try {
        let examDetails = [];
        examDetails = await this.examRepositoryInstance.getExamsOfACriteria(
          data
        );
        if (examDetails.length > 0) {
          examDetails.forEach(async (exam) => {
            if (exam.type === "Quick Test") {
              let obj = {
                instituteId: exam.instituteId,
                semId: exam.semId,
                examId: exam.examId,
              };
              var data = await this.getDetailsOfAnExam(obj);
              exam.subjectName = data.subjectName;
              this.quickTest.push(exam);
            } else {
              this.exams.push(exam);
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async showAnalysis(item) {
      if (this.isMobileView) {
        this.$router.push({
          name: "activityExamAnalysis2",
          params: {
            prop_exam: item,
          },
        });
      } else {
        this.$router.push({
          name: "activityExamAnalysis",
          params: {
            prop_exam: item,
            prop_allAttempts: this.allAttemptAnswers,
          },
        });
      }
    },
    showAnalysisEk(item) {
      window.open(item.ResultURL, "_blank");
    },
    appearForExam(item) {
      this.$store.commit("liveData/set_selectedExam", item);
      if (this.isMobileView) {
        this.$router.push("/activityAppearForExam2");
      } else {
        this.$router.push({
          name: "activityAppearForExam",
          params: {
            prop_startTime: new Date(),
          },
        });
        // ("/activityAppearForExam");
      }
    },
    appearForExamEk(item) {
      window.open(item.ExamURL, "_blank");
    },
    convertSecondstoTimeString(duration) {
      const hours = Math.floor(duration / 3600);
      duration %= 3600;
      const minutes = Math.floor(duration / 60);
      const seconds = duration % 60;
      if (hours === 0) {
        // return minutes + ' Minutes ' + seconds + ' Seconds'
        return minutes + " Minutes ";
      } else {
        // return hours + ' Hours ' + minutes + ' Minutes ' + seconds + ' Seconds'
        return hours + " Hours " + minutes + " Minutes ";
      }
    },
    convertDateTOISO(date) {
      const d = new Date(date);
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    },
    refreshData() {
      this.fetchExams();
    },
    checkIfExamIsNow(exam) {
      const fiveSecondsLater = addSeconds(new Date(), 5);
      const examDate = parseISO(exam.dateTimeUTC);
      return isAfter(examDate, fiveSecondsLater);
    },
    onSelectSubject() {
      const categories = [];
      const marks = [];
      let sum = 0;
      this.options.xaxis.categories = [];
      if (this.selectedSubjectId === "all") {
        for (const test of this.quickTest) {
          if (test.state === "conducted") {
            categories.push(test.name);
            if (isNaN(test.totalMarksObtained)) {
              marks.push(0);
              sum += 0;
            } else {
              marks.push(test.totalMarksObtained);
              sum += test.totalMarksObtained;
            }
          }
        }
      } else {
        const subjectName =
          this.subjectsMapping[this.selectedSubjectId].subjectName;
        for (const test of this.quickTest) {
          if (test.state === "conducted" && test.subjectName === subjectName) {
            categories.push(test.name);
            if (isNaN(test.totalMarksObtained)) {
              marks.push(0);
              sum += 0;
            } else {
              marks.push(test.totalMarksObtained);
              sum += test.totalMarksObtained;
            }
          }
        }
      }

      const AVG = (sum / marks.length).toFixed(2);
      if (isNaN(AVG)) {
        this.subjectAverage = 0;
      } else {
        this.subjectAverage = AVG;
      }
      this.options = {
        ...this.options,
        xaxis: { categories: categories.reverse() },
      };
      this.series = [{ name: "marks", data: marks.reverse() }];
    },
    isToday(dateString) {
      const dateArray = dateString.split(" ").filter((value) => value !== "");
      const date = new Date(dateArray[0], dateArray[1], dateArray[2]);
      const today = new Date();
      return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
    },
    handleTypeOfExams(type) {
      this.filterExams(type)
      this.selectedExamsType = type;
      this.handleExamType(this.selectedSubjectId);
    },
  },
};
</script>
<style src="./activityExams.css"></style>
