export default {
  getAllLectureOfValueAddedActivity: '/lectures/getAllLectureOfValueAddedActivity',
  getLecturesForADayForAStudent: '/lectures/getLecturesForADayForAStudent',
  getGroupLecturesForADayForAStudent: '/lectures/getGroupLecturesForADayForAStudent',
  getAttendanceAnalysisForAStudent: '/lectures/getAttendanceAnalysisForAStudent',
  getAllLecForAssignedSubjectForStudent: '/lectures/getAllLecForAssignedSubjectForStudent',
  allLectureOfDiv: '/lectures/allLectureOfDiv',
  getLectureDetailsOfLecturesForATopic: '/lectures/getLectureDetailsOfLecturesForATopic',
  getLectureById: '/lectures/getLectureById',
  getLecturesBySubject: '/lectures/getLecturesBySubject'
}
