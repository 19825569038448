import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/lectures'

class LectureRepository {
  constructor (context) {
    this.context = context
  }

  async getAllLecForAssignedSubjectForStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLecForAssignedSubjectForStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async allLectureOfDiv (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.allLectureOfDiv, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLectureById (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureById, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getAllLectureOfValueAddedActivity (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllLectureOfValueAddedActivity, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }

  async getLecturesForADayForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLecturesForADayForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getGroupLecturesForADayForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getGroupLecturesForADayForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getAttendanceAnalysisForAStudent (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAttendanceAnalysisForAStudent, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.attendanceAnalysis
  }

  async getLectureDetailsOfLecturesForATopic (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getLectureDetailsOfLecturesForATopic, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lectures
  }

  async getLecturesBySubject(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getLecturesBySubject, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.lecture
  }
}

export default LectureRepository
