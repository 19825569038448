<template src="./activityAttendanceAnalysis.html"></template>

<script>
// import ApexCharts from "apexcharts";
import { subjectTypes } from "../../Constants/Utils/Statics";
import { convertUTCDateToLocalDate } from "../../Services/Utils/DateTimeFormatter";
import inputContainer from "../../Components/inputContainer";
import SemesterUserRepository from "../../Repository/SemesterUser";
import SemesterRepository from "../../Repository/Semester";
import LectureRepository from "../../Repository/lectures";
import SubjectsRepository from "../../Repository/Subjects";
import TopicRepository from "../../Repository/Topic";
import TopicFeedbackRepository from "../../Repository/TopicFeedback";
import VueApexCharts from "vue-apexcharts";

// import { toDate } from "date-fns";
// import moment from "moment";
// import Vue from "vue";
// import {
//   Vuetify,
//   VApp,
//   VCard,
//   Vslidegroup,
//   Vrating
//   /* other imports ... */
// } from "vuetify";
// import {
//   convertDate,
//   convertTime
// } from "../../Services/Utils/DateTimeFormatter";
export default {
  name: "activityAttendanceAnalysis",
  components: {
    inputContainer,
    apexcharts: VueApexCharts
  },
  data() {
    return {
      series: [{
        name: "Overall Attendance",
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Overall Attendance',
          align: 'left'
        },
        xaxis: {
          labels: { rotateAlways: true }
        }
      },
      overallXAxis: [],
      overallYAxis: [],
      overallPercent: 0,
      allSubjectOverall: 0,
      totalConductedCount: 0,
      totalAttendCount: 0,
      facultyNameObj: {},
      headerForAnalysis: [
        {
          text: 'My Attendance',
          value: 'attendance'
        },
        {
          text: 'Date',
          value: 'startTime',
          width: '150px'
        },
        {
          text: 'Time',
          value: 'time',
          width: '200px'

        },
        {
          text: 'Day',
          value: 'day'
        },
        {
          text: 'Faculty Name',
          value: 'facultyName',
          width: '150px'

        },
        {
          text: 'Lecture Status',
          value: 'status'
        },
        {
          text: 'Lecture Type',
          value: 'type'
        },
        {
          text: 'Lecture Details',
          value: 'details',
          width: '200px'

        }
      ],
      lecDataForAnalysis: [],
      allLec: [],
      allFaculty: [],
      startDate: '',
      endDate: '',
      menu1: false,
      menu: false,
      selectSubjectForAnalysis: '',
      allSubjectList: [],
      isMobileView: false,
      userData: {},
      selectedInstitute: '',
      selectedSemester: {},
      subjectsMapping: {},
      objectForAllLec: {},
      subjectTypeWiseSubjects: {}
    };
  },
  created() {
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    console.log('isMobileView', this.isMobileView);
    this.topicRepositoryInstance = new TopicRepository(this);
    subjectTypes.forEach((subjectType) => {
      this.subjectTypeWiseSubjects[subjectType] = [];
    });
    this.userData = this.$store.getters["user/get_userData"];
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
    this.$store.commit("liveData/set_selectedActivityName", "Attendance Analysis");
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.lectureRepositoryInstance = new LectureRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.topicRepositoryInstance = new TopicRepository(this);
    this.topicFeedbackRepositoryInstance = new TopicFeedbackRepository(this);
    this.subjectsMapping = this.$store.getters["mappings/get_subjectsMapping"];
    this.fetchData();
    // this.fetchDataNew();

  },
  methods: {
    async endDateChanged() {
      await this.getAllLecForStudent()
    },
    calculateOverallData() {
      this.overallXAxis = []
      this.overallYAxis = []
      this.allSubjectOverall = 0
      const keys = Object.keys(this.objectForAllLec)
      if (keys.length > 0) {
        keys.forEach((key) => {
          this.overallXAxis.push(this.subjectsMapping[key]['subjectName'])
          this.overallYAxis.push(this.calculateOverallAttendance(this.objectForAllLec[key]))
          this.allSubjectOverall = this.allSubjectOverall + Number(this.calculateOverallAttendance(this.objectForAllLec[key]))
        })
      }
      this.allSubjectOverall = (this.allSubjectOverall / this.overallXAxis.length).toFixed(2)
      this.series = [{ name: "Overall Attendance", data: this.overallYAxis }]

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: this.overallXAxis }
      }

    },
    convertDateToDay(utcDateString) {
      const utcDate = new Date(utcDateString);
      const localDate = new Date(utcDate.toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
      const dayName = localDate.toLocaleDateString("en-US", { weekday: "long" });
      return dayName;
    },
    calculateAllSubjectLec() {
      this.objectForAllLec = {}
      let flagForBatch = false
      if (this.userData.batch && this.userData.batch !== '') {
        flagForBatch = true
      }
      if (this.allLec.length > 0) {
        // const allSubjectKeys = Object.keys(this.subjectsMapping)
        if (this.userData.subjects.length > 0) {
          this.userData.subjects.forEach((id) => {
            let isSubjectForGrp = false
            let groupId = ''
            if (this.userData.assignedGroupForSubject) {

              this.userData.assignedGroupForSubject.forEach((grp) => {
                if (grp.subjectId === id) {
                  isSubjectForGrp = true
                  groupId = grp.groupId
                  groupId = grp.groupId
                }
              })
            }
            this.objectForAllLec[id] = []
            this.allLec.forEach((lec) => {
              if (lec.subjectId === id) {

                if (isSubjectForGrp === true) {
                  if (Array.isArray(lec.groupForSubject) && lec.groupForSubject.length > 0) {
                    lec.groupForSubject.forEach((grp) => {
                      if (grp.groupId === groupId) {
                        this.objectForAllLec[id].push(lec)
                      }
                    })
                  }
                } else if (flagForBatch === true) {
                  if (lec.division === this.userData.division && lec.batch === this.userData.batch) {
                    this.objectForAllLec[id].push(lec)
                  }

                } else {
                  if (lec.division === this.userData.division || lec.multiDivision.includes(this.userData.division)) {
                    this.objectForAllLec[id].push(lec)
                  }
                }
              }
            })
          })
        }
      }
      if (this.allSubjectList.length > 0) {

        this.selectSubjectForAnalysis = this.allSubjectList[0]
        this.subjectSelected()
      }
    },
    async getAllLecForStudent() {
      try {
        const groupIds = []
        if (Array.isArray(this.userData.assignedGroupForSubject) && this.userData.assignedGroupForSubject.length > 0) {
          this.userData.assignedGroupForSubject.forEach((grp) => {
            groupIds.push(grp.groupId)
          })
        }
        const currentDate = new Date(this.startDate);
        currentDate.setDate(currentDate.getDate() - 1);
        const startDate = currentDate.toISOString()
        const startDateForLec = startDate.split('T')[0]
        const min = `${startDateForLec}T18:30:00`
        const max = `${this.endDate}T18:30:59`
        const obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.userData.department,
          courseYear: this.userData.courseYear,
          division: this.userData.division,
          groupIds: groupIds,
          subjectIds: this.userData.subjects,
          toDate: max,
          fromDate: min
        }
        const data = await this.lectureRepositoryInstance.getAllLecForAssignedSubjectForStudent(obj)
        this.allLec = data.lecture
        this.allFaculty = data.fullNames
        if (this.allFaculty.length > 0) {
          this.allFaculty.forEach((fac) => {
            this.facultyNameObj[fac.uId] = fac.fullName
          })
        }
        this.calculateAllSubjectLec()
        this.calculateOverallData()
      } catch (error) {

      }
    },
    calculateOverallAttendance(lecArr) {
      let conducted = 0
      let attend = 0
      let overall = 0
      if (lecArr.length > 0) {
        lecArr.forEach((data) => {
          if (data.present.includes(this.userData.uId) || data.absent.includes(this.userData.uId)) {
            conducted++;
          }
          if (data.present.includes(this.userData.uId)) {
            attend++;
          }
        })
        if (conducted > 0) {
          overall = (attend * 100 / conducted).toFixed(2)
        }
      }
      return Number(overall)
    },
    subjectSelected() {
      this.lecDataForAnalysis = this.objectForAllLec[this.selectSubjectForAnalysis.subjectId]
      this.totalConductedCount = 0
      this.totalAttendCount = 0
      this.overallPercent = 0
      if (this.lecDataForAnalysis.length > 0) {
        this.lecDataForAnalysis.forEach((data) => {
          if (data.present.includes(this.userData.uId) || data.absent.includes(this.userData.uId)) {
            this.totalConductedCount++;
          }
          if (data.present.includes(this.userData.uId)) {
            this.totalAttendCount++;
          }
          data.facultyName = this.facultyNameObj[data.uId]
          data.time = `${convertUTCDateToLocalDate(data.startTime)} - ${convertUTCDateToLocalDate(data.endTime)}`
          data.day = this.convertDateToDay(data.startTime)
          if (data.status === 6) {
            data.status = 'Canceled'

          } else {
            if (data.isConducted === true) {
              data.status = 'Conducted'
            } else {
              data.status = 'Not Conducted'

            }
          }
          if (!data.present.includes(this.userData.uId) && !data.absent.includes(this.userData.uId) && data.isConducted === true) {
            data.attendance = 'NA'
          }
          if (data.isConducted === false) {
            data.attendance = '-'
          }
          if (data.present.includes(this.userData.uId)) {
            data.attendance = 'Present'
          }
          if (data.absent.includes(this.userData.uId)) {
            data.attendance = 'Absent'
          }

          if (Array.isArray(data.multiDivision) && data.multiDivision.length > 0) {
            data.type = 'Multi Division'
            data.details = `${data.department} | ${data.courseYear} | ${data.multiDivision}`
          } else if (data.batch && data.batch !== '') {
            data.type = 'Batch'
            data.details = `${data.department} | ${data.courseYear} | ${data.division} | ${data.batch}`
          } else if (Array.isArray(data.groupForSubject) && data.groupForSubject.length > 0) {
            data.type = 'Group'
            data.details = `${data.department} | ${data.courseYear} | ${data.data.groupForSubject[0].groupName} `
          } else {
            data.type = 'Division'
            data.details = `${data.department} | ${data.courseYear} | ${data.division} `

          }

        })
        this.overallPercent = this.calculateOverallAttendance(this.lecDataForAnalysis)

      }

    },
    async getSemDetails() {
      this.startDate = '2023-09-01'
      this.endDate = new Date().toISOString().split('T')[0]
      try {
        const data = await this.semesterRepositoryInstance.getSemestersBySemId(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId
          }
        )
        this.startDate = data.length > 0 ? data[0].startDate.split('T')[0] : ''
      } catch (error) {
        console.error('error', error);
      }
    },
    async fetchData() {
      this.allSubjectList = []
      // const allSubjectKeys = Object.keys(this.subjectsMapping)
      if (this.userData.subjects.length > 0) {
        this.userData.subjects.forEach((id) => {
          this.allSubjectList.push(
            {
              subjectName: this.subjectsMapping[id]['subjectName'],
              subjectId: this.subjectsMapping[id]['subjectId']
            }

          )
        })
      }
      await this.getSemDetails()
      await this.getAllLecForStudent()
    }

  }
};
</script>

<style src="./activityAttendanceAnalysis.css"></style>
