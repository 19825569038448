<template src='./activityChangeSemester.html'></template>

<script>
import SubjectsRepository from '../../Repository/Subjects';
import SemesterUserRepository from '../../Repository/SemesterUser';
import SemesterRepository from '../../Repository/Semester';
import showStatus from '../../NetworkManager/showStatus';
import TimetableRepository from '../../Repository/timetable';
import { subjectTypes } from "../../Constants/Utils/Statics";
// import { visitedRouteName } from '../../Router/index';
import UserAuthenticationRepository from "../../Repository/UserAuthentication";

export default {
  name: 'activityChangeSemester',
  data() {
    return {
      isCorporateInstitute: false,
      selectedListItem: [],
      filteredSemList: [],
      visitedRoutesDummy: [],
      studentInformation: [],
      alignment: 'center',
      justify: 'center',
      userData: null,
      selectedInstitute: '',
      selectedSemester: '',
      semesters: [],
      currentlyChosenSemester: '',
      isLoading: false,
      isMobileView: false,
      steps: [
        {
          target: '#step-1',
          // targetElementId: '#step-1',
          header: {
            title: 'Welcome to student portal'
          },
          content: `Page guide`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#qrCode',
          content: 'Attendance Scanner',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#step-2',
          content: 'Notification',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#step-3',
          content: 'My profile',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#step-4',
          content: 'Select semester here',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#step-5',
          content: 'Click on desired tab',
          params: {
            placement: 'left',
            enableScrolling: false
          }
        }
      ]
    }
  },
  created() {
    let name = 'Change Semester'

    if (process.env.VUE_APP_WHITELABEL_NAME === 'aspire') {
      name = 'Select Program'
    }
    this.$store.commit("liveData/set_selectedActivityName", name);
    // this.$store.commit('liveData/set_selectedActivityName', 'Change Semester')
    this.userData = this.$store.getters['user/get_userData']
    this.selectedInstitute = this.$store.getters['instituteData/get_selectedInstitute']
    this.selectedSemester = this.$store.getters['instituteData/get_selectedSemester']
    this.semesterRepositoryInstance = new SemesterRepository(this)
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this)
    this.currentlyChosenSemester = this.selectedSemester.semId
    this.timetableRepositoryInstance = new TimetableRepository(this)
    this.UserAuthenticationRepositoryInstance = new UserAuthenticationRepository(this)
    this.subjectsRepositoryInstance = new SubjectsRepository(this)
    this.isMobileView = this.$store.getters["liveData/get_isMobileView"];
    this.isCorporateInstitute = this.$store.getters["liveData/get_isCorporateInstitute"];
    this.fetchData()
    this.fetchSubjectData()
    this.startSiteTour()
  },

  methods: {
    temp(item) {
      this.semesters.map((data) => {
        if (item.selectedCourseYear === data.courseYear && item.semName === data.semName) {
          this.chooseAndUpdateSemester(data)
        }
      })
    },
    async startSiteTour() {
      this.studentInformation = [];
      this.routeName = this.$route.name
      const objToPush = {
        uId: this.userData.uId
      }
      this.studentInformation = await this.UserAuthenticationRepositoryInstance.getUserAuthenticationByUid(objToPush)
      if (!this.studentInformation.userAuthentications[0].visitedRoutes.includes(this.routeName)) {
        setTimeout(() => {
          // console.log('hiii');
          this.$tours[this.routeName].start()
        }, 1);
        this.studentInformation.userAuthentications[0].visitedRoutes.push(this.routeName)
        this.visitedRoutesDummy = [...this.studentInformation.userAuthentications[0].visitedRoutes]
        // console.log('this.studentInformation.userAuthentications[0].visitedRoutes', this.studentInformation.userAuthentications[0]);
        // console.log('this.visitedRoutesDummy', this.visitedRoutesDummy);
        const objToPush1 = {
          uId: this.userData.uId,
          visitedRoutes: this.visitedRoutesDummy
        }
        // console.log('objToPush1', objToPush1);
        await this.UserAuthenticationRepositoryInstance.updateVisitedRoutes(objToPush1)
      }
    },
    async fetchSubjectData() {
      try {
        const subjectsFromStore = this.$store.getters['mappings/get_subjectsMapping']
        const subjectsFromStoreToArray = Object.keys(subjectsFromStore).map(subjectId => subjectsFromStore[subjectId])
        if (Array.isArray(subjectsFromStoreToArray) && subjectsFromStoreToArray.length > 0) {
          this.subjects = subjectsFromStoreToArray;
          return
        }
        this.isFetchingSubjects = true
        const subjects = await this.subjectsRepositoryInstance.getSubjects({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: [...this.userData.subjects]
        })
        for await (const subject of subjects) {
          subject.subjectType = subjectTypes[subject.subjectType]
          this.$store.commit("mappings/add_subjectsMapping", subject);
        }
        this.subjects = subjects
      } catch (err) {
        console.log(err);
        alert("An error occured, refresh or try again later")
      } finally {
        this.isFetchingSubjects = false
      }
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const objToPush = {
          instituteId: this.selectedInstitute,
          uId: this.userData.uId
        };
        try {
          this.semesters =
            await this.semesterUserRepositoryInstance.getSemesterUsersForAUser(
              objToPush
            );
          this.semesters.map((data) => {
            data['courseYearName'] = []
            this.semesters.map((info) => {
              if (data.semId === info.semId) {
                data.courseYearName.push(info.courseYear)
              }
            })
          })
          console.log('this.semesters', this.semesters);
          this.filteredSemList = []
          this.filteredSemList = this.semesters.filter((obj, index) =>
            this.semesters.findIndex((item) => item.semId === obj.semId) === index
          );
          // this.semesters = this.filteredSemList
        } catch (err) {
          console.log(err);
          this.semesters = [];
        }

        const semIdToSemNameMapping = {};
        let semesterNamesArray = []
        try {
           semesterNamesArray =
            await this.semesterRepositoryInstance.getNamesOfSemesters(
              objToPush
            );
          semesterNamesArray.forEach((semesterNameObject) => {
            semIdToSemNameMapping[semesterNameObject.semId] = semesterNameObject.semNameForStudent ? semesterNameObject.semNameForStudent : semesterNameObject.semName

          });
        } catch (err) {
          console.log(err);
        }
        this.semesters.forEach((semester) => {
          semester.isActive = false
          if (semIdToSemNameMapping[semester.semId]) {
            semester.semName = semIdToSemNameMapping[semester.semId];
          } else {
            semester.semName = "";
          }
          semesterNamesArray.forEach((semesterNameObject) => {
            if (semesterNameObject.semId === semester.semId && semesterNameObject.isSemStatusActive === true) {
              semester.isActive = true
            }
          })
        });
        this.semesters = this.semesters.slice();
        if (this.semesters.length <= 1) {
          this.chooseAndUpdateSemester(this.semesters[0])
        }
      } catch (e) {
        console.error(e);
        alert("An error occured");
      } finally {
        this.isLoading = false;
      }
    },
    async chooseAndUpdateSemester(semesterObject) {
      const { uId, ...rest } = semesterObject
      const dataToSave = {
        ...this.userData,
        ...rest
      }
      this.selectedSemester = semesterObject;
      await this.getSubjectsFromServer()
      this.currentlyChosenSemester = semesterObject.semId;
      this.$store.dispatch("user/set_userData", dataToSave);
      this.$store.commit("instituteData/set_selectedSemester", semesterObject);
      // this.$store.commit("mappings/reset");
      showStatus("Updation successful", 1000, "success", this);
      if (this.isCorporateInstitute === true) {
        this.$store.dispatch("liveData/set_selectedActivity", 0);
        this.$router.push('/activityCorporateDashboard')
      } else {
        if (!this.isMobileView) {
          this.$router.push('/activityMySubjects')
        } else {
          this.$router.push('/activityMySubjects2')
        }
      }
    },
    async getSubjectsFromServer() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.selectedSemester.semId,
        uId: this.userData.uId
      };
      let subjectObjects;
      try {
        subjectObjects = await this.timetableRepositoryInstance.getAllocationsOfAFaculty(
          objToPush
        );
      } catch (err) {
        console.log(err);
        subjectObjects = [];
      }
      const subjectIds = subjectObjects.map(item => item.subjectId);
      try {
        const getsubjectObjToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: subjectIds
        }
        const subjects = await this.subjectsRepositoryInstance.getSubjectDetails(
          getsubjectObjToPush
        );
        subjects.map((obj) => {
          obj.subjectType = this.subjectTypes[obj.subjectType];
          this.$store.commit('mappings/add_subjectsMapping', obj)
        })
      } catch (err) {
        console.log(err);
      }
    }
  }
}
</script>

<style src='./activityChangeSemester.css'></style>

<style src="./activityChangeSemester.css"></style>
