const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const formatDateTimeToDate = dateTime => {
  return dateTime.substr(0, 10)
}

export function getDay(date) {
  const D = new Date(date.split('/')[2], (parseInt(date.split('/')[1]) - 1), date.split('/')[0])
  console.log(D, date.split('/')[0], (parseInt(date.split('/')[1]) - 1), date.split('/')[2])
  return days[D.getDay()]
}

export function convertDate(date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  // return [year, month, day].join('-')
  return [day, month, year].join('-')
}

export function convertTime(date) {
  const d = new Date(date)
  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)

  return timeStringConverter([hours, minutes].join('_'))
}

export function convertDateTime(date) {
  return convertDate(date) + ' ' + convertTime(date)
}

export function formatToSlashSepearated(date) {
  const d = new Date(date).toLocaleDateString().split('/')

  return [d[1], d[0], d[2]].join('/')
}

export function convertFromYYYYMMDDToDate(dateString, timeString) {
  const dateArray = dateString.split('-').map((date) => parseInt(date))
  dateArray[1] -= 1
  console.log(dateArray)
  const date = new Date(
    ...dateArray
  )

  return convertToISTTime(date)
}

export function convertToISTTime(date) {
  const currentOffset = date.getTimezoneOffset('HH:MM')

  // IST offset UTC +5:30
  const ISTOffset = 330

  const ISTTime = new Date(date.getTime() + (ISTOffset + currentOffset) * 60000)

  console.log('ISTTime', ISTTime)

  return ISTTime
}
export function convertToLocalTime(time) {
  const tempTime = time.split('_')
  const date = new Date()
  const currentOffset = date.getTimezoneOffset()
  const hr = currentOffset / 60
  const temphr = hr.toString()
  if (currentOffset > 0) {
    tempTime[0] = Math.abs(parseInt(tempTime[0]) - Math.ceil(hr))
  } else {
    tempTime[0] = Math.abs(parseInt(tempTime[0]) + Math.ceil(hr))
  }
  if (isNaN(temphr.split('.'))) {
    tempTime[1] = (isNaN(temphr.split('.')[1] * 6) ? temphr.split('.')[1] * 6 : '00')
  }
  const n = tempTime[0] < 10 ? `0${tempTime[0]}_${tempTime[1]}`.toString() : `${tempTime[0]}_${tempTime[1]}`.toString()
  console.log('n', n);
  return time
}

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
  // console.log('newDate', newDate);
  return newDate;
}
// export function formatToDashSeparated(date) {
//   let dateSplit = date.split("/");
//   let day = dateSplit[0].length === 1 ? `0${dateSplit[0]}` : dateSplit[0];
//   let month = dateSplit[1].length === 1 ? `0${dateSplit[1]}` : dateSplit[1];
//   let year = dateSplit[2];
//   return [year, month, day].join("-");
// }

export function timeStringConverter(time) {
  time = time.split('_').join(':')
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]
  if (time.length > 1) { // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}
